// Config & Theme
import {
  DefaultConfigOptions,
  createInput,
  defineFormKitConfig,
} from '@formkit/vue'
import { defu } from 'defu'
import { generateClasses } from '@formkit/themes'
import defaultConfig from '@flnt-accurait/web-components/formkit.config'
import theme from './formkit.theme'
// Components
import {
  listbox,
  taglist,
  leaseDocument,
  form,
  text,
  datepicker,
  numberIncrement,
} from './inputs'
// Plugins
import '@formkit/addons/css/multistep'
import { createProPlugin, inputs } from '@formkit/pro'

export default defineFormKitConfig(() => {
  const { formkitKey } = useRuntimeConfig().public

  return defu<DefaultConfigOptions, DefaultConfigOptions[]>(
    {
      config: {
        classes: generateClasses(theme),
      },
      locale: 'en',
      inputs: {
        form,
        text,
        leaseDocument,
        listbox,
        taglist,
        datepicker,
        numberIncrement,
      },

      plugins: [createProPlugin(formkitKey, inputs)],
      icons: {
        invalid: `
      <svg class="h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
      </svg>`,
      },
    },
    defaultConfig,
  )
})
