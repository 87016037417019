<script lang="ts">
export default defineComponent({
  inheritAttrs: false,
})
</script>

<script setup lang="ts">
import {
  TransitionRoot,
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
} from '@headlessui/vue'

import type { GoogleAddressPrediction } from '~/types/googleAddress'
import type { LeaseDocumentInputContext } from '../types'

interface Props {
  context: LeaseDocumentInputContext
  id: string
  name: string
  modelValue?: any
  isMultiline?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: undefined,
  isMultiline: false,
})

const emit = defineEmits(['update:modelValue'])

const modelValue = useVModel(props, 'modelValue', emit, { passive: true })

const { getPlacePredictions, getPlaceDetails, mapGoogleAddress } =
  useGoogleAddressAutoComplete()

/* === PREDICTIONS ===*/
const addressPredictions = ref<GoogleAddressPrediction[]>([])
async function search(_query: string) {
  modelValue.value = _query

  if (!_query) return
  addressPredictions.value = await getPlacePredictions(_query)
  if (addressPredictions.value.length === 1) {
    onSelect(addressPredictions.value[0])
  }
}
/* === PREDICTIONS ===*/

/* === VALUE ===*/

const onSelect = (address: GoogleAddressPrediction | undefined) => {
  if (address === undefined) return
  // a valid detailed address is fetched when user selects an address from the options
  getPlaceDetails(address.place_id, (addr) => {
    // set the value
    modelValue.value = {
      ...mapGoogleAddress(addr),
      description: address.description,
      place_id: address.place_id,
    }
    // reset the bound box
    props.context.node.input({
      value: modelValue.value,
      bounds: props.context?.value?.bounds,
    })
  })
}

const displayValue = (value: string | GoogleAddressPrediction) => {
  if (typeof value === 'object') {
    return value.description
  }

  return value
}
/* === VALUE ===*/

/* === PREVENT MULTILINE === */
const onKeydownEnter = (e: Event) => {
  if (!props.isMultiline) {
    e.preventDefault()
  }
}
/* === PREVENT MULTILINE === */

watch(
  () => props.modelValue,
  (value) => {
    if (typeof value === 'string') {
      search(value)
    }
  },
  { immediate: true },
)
</script>

<template>
  <Combobox :model-value="modelValue" @update:model-value="onSelect">
    <ComboboxInput
      :id="id"
      as="textarea"
      :name="name"
      v-bind="$attrs"
      :display-value="displayValue"
      class="w-full border-none bg-transparent focus:outline-none focus:ring-0"
      @keydown.enter="onKeydownEnter"
      @change="search($event.target.value)"
    />

    <TransitionRoot
      leave="transition ease-in duration-100"
      leave-from="opacity-100"
      leave-to="opacity-0"
    >
      <ComboboxOptions
        class="absolute left-0 top-full z-50 mt-2 w-full overflow-y-auto scroll-smooth rounded-lg border border-gray-500 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-gray-800"
      >
        <div
          v-if="addressPredictions.length === 0"
          class="cursor-default select-none whitespace-nowrap p-3 text-sm text-gray-200 transition duration-100"
        >
          Nothing found.
        </div>
        <ComboboxOption
          v-for="prediction in addressPredictions"
          :key="prediction.place_id"
          :value="prediction"
          class="ui-selected:text-white ui-active:text-white ui-selected:dark:bg-gray-750 ui-active:dark:bg-gray-700 max-w-full cursor-pointer truncate whitespace-nowrap p-3 text-xs text-gray-200 transition duration-100"
        >
          {{ prediction.description }}
        </ComboboxOption>
      </ComboboxOptions>
    </TransitionRoot>
  </Combobox>
</template>
