import { type FormKitNode, createMessage } from '@formkit/core'
import {
  ConfidenceLevel,
  LeaseFieldType,
  type ILeaseField,
  type ILeaseDocumentField,
  type ILeaseRegisterSettings,
  type ILeaseDates,
} from '@register'
import type { Combine } from '~/types/combine'
import type { FieldConfig } from '../types'
import { useFieldSelection } from '@register/components/Review/composables'
import { fieldConfigFactory } from '../config'
import { LeaseDocumentValue } from '../types'

declare module '@formkit/core' {
  interface FormKitNodeExtensions {
    addNote: () => void
    copyFromDocument: () => void
    backlinkingOnly: () => void
  }

  interface FormKitFrameworkContext {
    field: Combine<ILeaseField, ILeaseDocumentField>
    fieldConfig: FieldConfig
    leaseSettings: ILeaseRegisterSettings
    isEdited: boolean
    isApproved: boolean
    _initApproved: boolean
    confidence: number | null
    confidenceLevel: ConfidenceLevel
    preventNextRequest?: boolean
    fieldAddressNode?: FormKitNode
    canApprove: boolean
    canDelete: boolean
    hideMapButton: boolean
    sideActions: boolean
    actions: boolean
    dates?: ILeaseDates
    originalDates?: ILeaseDates
  }
}

/**
 * A feature that set up the field context.
 *
 * @param node - A {@link @formkit/core#FormKitNode | FormKitNode}.
 *
 * @public
 */
export function setupContext(node: FormKitNode): void {
  node.on('created', () => {
    node.context.fns.setupContext = () => _setupContext(node)
    node.context.fns.setupContext()
  })
}

function _setupContext(node: FormKitNode) {
  // Field
  const field = node.context.field
  // Config
  node.context.fieldConfig = fieldConfigFactory(node)
  // Actions
  node.context.actions = node.props.actions ?? true
  // sideActions
  node.context.sideActions = node.props.sideActions ?? true

  // Confidence
  node.context.confidence = field.value?.confidence ?? null
  node.context.confidenceLevel =
    field.value?.confidenceLevel ?? ConfidenceLevel.NONE
  // Is edited
  node.context.isEdited = field.value?.isEdited ?? false
  // Approved
  node.context.isApproved = node.context._initApproved =
    field.value?.isApproved ?? false

  if (node.context.isApproved) {
    node.store.set(createMessage({ key: 'isApproved', visible: false }))
  }
  // Field selection
  const { selectedField, snippetRowPosition, fieldSelectionState } =
    useFieldSelection()
  node.context.selected = computed(() => selectedField.value?.id === field.id)
  node.context.showSnippet = computed(
    () =>
      node.context.selected &&
      !!snippetRowPosition.value &&
      !!fieldSelectionState.snippet &&
      !!(node.context._value as LeaseDocumentValue).bounds &&
      (node.context._value as LeaseDocumentValue).bounds!.bounds.length > 0,
  )

  // Force table
  node.context.fns.toggleTable = () => {
    node.context.forceTable = !node.context.forceTable
    node.context.fieldConfig = fieldConfigFactory(node)
  }

  //can delete field
  const checkCanDelete = (node: FormKitNode, fieldType?: LeaseFieldType) => {
    return node.props.canDelete
  }
  node.context.canDelete = checkCanDelete(node, field.fieldType)

  // can approve field
  const checkCanApprove = (node: FormKitNode, displayType?: string) => {
    // if (displayType === 'noApproval') return false
    return node.props.canApprove ?? true
  }

  node.context.canApprove = checkCanApprove(node, field.displayType)

  // hide map button
  node.context.hideMapButton = node.props.hideMapButton ?? false

  node.context.dates = field.value?.dates

  // @ts-expect-error
  node.context.originalDates = field.value?.originalDates
}
