<script setup lang="ts">
import type { PaginationState } from '@tanstack/vue-table'

const props = defineProps<{
  rowsPerPageOptions: number[]
  canPreviousPage: boolean
  canNextPage: boolean
  totalRecords: number
  pagination: PaginationState
  loading: boolean
}>()

defineEmits<{
  (e: 'previousPage'): void
  (e: 'nextPage'): void
  (e: 'pageSize', value: number): void
}>()

const rowsPerPageOptions = computed(() => {
  return props.rowsPerPageOptions.map((value) => ({
    label: String(value),
    value,
  }))
})

const startIndex = computed(() => {
  return props.pagination.pageIndex * props.pagination.pageSize + 1
})
const endIndex = computed(() => {
  return Math.min(
    (props.pagination.pageIndex + 1) * props.pagination.pageSize,
    props.totalRecords,
  )
})

const disabled = computed(() => props.loading && !props.totalRecords)
</script>

<template>
  <div
    class="sticky left-0 right-0 flex items-center justify-end gap-4 border-t border-gray-800 px-4 py-2.5 text-sm"
  >
    <div class="flex items-center gap-2">
      <span> rows per page: </span>
      <FormKit
        type="listbox"
        :disabled="disabled"
        :options="rowsPerPageOptions"
        format="value"
        :model-value="pagination.pageSize"
        @update:model-value="$emit('pageSize', $event)"
      />
    </div>
    <span class="flex gap-1">
      <span> {{ startIndex }} - {{ endIndex }} </span>
      <span class="text-gray-400">of</span>
      <span>
        {{ totalRecords }}
      </span>
    </span>
    <div class="flex items-center gap-2">
      <button
        :disabled="disabled || !canPreviousPage"
        class="flex rounded-full text-black disabled:opacity-50 dark:text-white"
        :class="[
          !disabled &&
            canPreviousPage &&
            'hover:bg-primary cursor-pointer hover:text-black dark:hover:text-black',
        ]"
        @click="$emit('previousPage')"
      >
        <Icon
          name="chevron-left"
          filled
          class="flex h-8 w-8 items-center justify-center text-current dark:group-hover:text-black"
        />
      </button>
      <button
        :disabled="disabled || !canNextPage"
        class="flex rounded-full text-black disabled:opacity-50 dark:text-white"
        :class="[
          !disabled &&
            canNextPage &&
            'hover:bg-primary cursor-pointer hover:text-black dark:hover:text-black',
        ]"
        @click="$emit('nextPage')"
      >
        <Icon
          name="chevron-right"
          filled
          class="flex h-8 w-8 items-center justify-center text-current dark:group-hover:text-black"
        />
      </button>
    </div>
  </div>
</template>
