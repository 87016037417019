
const iconImports = {
  "3d-report": () => import("/opt/build/repo/layers/ui/assets/icons/3d-report.svg?raw" /* webpackChunkName: "assets/icons/3d-report" */).then(c => c.default || c),
  "Cashflow": () => import("/opt/build/repo/layers/ui/assets/icons/Cashflow.svg?raw" /* webpackChunkName: "assets/icons/Cashflow" */).then(c => c.default || c),
  "ConsolidateCashflow": () => import("/opt/build/repo/layers/ui/assets/icons/ConsolidateCashflow.svg?raw" /* webpackChunkName: "assets/icons/ConsolidateCashflow" */).then(c => c.default || c),
  "amount-squre": () => import("/opt/build/repo/layers/ui/assets/icons/amount-squre.svg?raw" /* webpackChunkName: "assets/icons/amount-squre" */).then(c => c.default || c),
  "approve-all": () => import("/opt/build/repo/layers/ui/assets/icons/approve-all.svg?raw" /* webpackChunkName: "assets/icons/approve-all" */).then(c => c.default || c),
  "arrow-narrow-right": () => import("/opt/build/repo/layers/ui/assets/icons/arrow-narrow-right.svg?raw" /* webpackChunkName: "assets/icons/arrow-narrow-right" */).then(c => c.default || c),
  "asterisk": () => import("/opt/build/repo/layers/ui/assets/icons/asterisk.svg?raw" /* webpackChunkName: "assets/icons/asterisk" */).then(c => c.default || c),
  "backlinkingOnly": () => import("/opt/build/repo/layers/ui/assets/icons/backlinkingOnly.svg?raw" /* webpackChunkName: "assets/icons/backlinkingOnly" */).then(c => c.default || c),
  "basket": () => import("/opt/build/repo/layers/ui/assets/icons/basket.svg?raw" /* webpackChunkName: "assets/icons/basket" */).then(c => c.default || c),
  "bell-01": () => import("/opt/build/repo/layers/ui/assets/icons/bell-01.svg?raw" /* webpackChunkName: "assets/icons/bell-01" */).then(c => c.default || c),
  "bg-button-square": () => import("/opt/build/repo/layers/ui/assets/icons/bg-button-square.svg?raw" /* webpackChunkName: "assets/icons/bg-button-square" */).then(c => c.default || c),
  "building": () => import("/opt/build/repo/layers/ui/assets/icons/building.svg?raw" /* webpackChunkName: "assets/icons/building" */).then(c => c.default || c),
  "calendar-schedule-time": () => import("/opt/build/repo/layers/ui/assets/icons/calendar-schedule-time.svg?raw" /* webpackChunkName: "assets/icons/calendar-schedule-time" */).then(c => c.default || c),
  "calendar": () => import("/opt/build/repo/layers/ui/assets/icons/calendar.svg?raw" /* webpackChunkName: "assets/icons/calendar" */).then(c => c.default || c),
  "card-hover": () => import("/opt/build/repo/layers/ui/assets/icons/card-hover.svg?raw" /* webpackChunkName: "assets/icons/card-hover" */).then(c => c.default || c),
  "care": () => import("/opt/build/repo/layers/ui/assets/icons/care.svg?raw" /* webpackChunkName: "assets/icons/care" */).then(c => c.default || c),
  "chart-star-circle": () => import("/opt/build/repo/layers/ui/assets/icons/chart-star-circle.svg?raw" /* webpackChunkName: "assets/icons/chart-star-circle" */).then(c => c.default || c),
  "check": () => import("/opt/build/repo/layers/ui/assets/icons/check.svg?raw" /* webpackChunkName: "assets/icons/check" */).then(c => c.default || c),
  "checked": () => import("/opt/build/repo/layers/ui/assets/icons/checked.svg?raw" /* webpackChunkName: "assets/icons/checked" */).then(c => c.default || c),
  "checkmark-list": () => import("/opt/build/repo/layers/ui/assets/icons/checkmark-list.svg?raw" /* webpackChunkName: "assets/icons/checkmark-list" */).then(c => c.default || c),
  "chevron-down": () => import("/opt/build/repo/layers/ui/assets/icons/chevron-down.svg?raw" /* webpackChunkName: "assets/icons/chevron-down" */).then(c => c.default || c),
  "chevron-left": () => import("/opt/build/repo/layers/ui/assets/icons/chevron-left.svg?raw" /* webpackChunkName: "assets/icons/chevron-left" */).then(c => c.default || c),
  "chevron-right": () => import("/opt/build/repo/layers/ui/assets/icons/chevron-right.svg?raw" /* webpackChunkName: "assets/icons/chevron-right" */).then(c => c.default || c),
  "chevron-up": () => import("/opt/build/repo/layers/ui/assets/icons/chevron-up.svg?raw" /* webpackChunkName: "assets/icons/chevron-up" */).then(c => c.default || c),
  "clock-time-arow-left": () => import("/opt/build/repo/layers/ui/assets/icons/clock-time-arow-left.svg?raw" /* webpackChunkName: "assets/icons/clock-time-arow-left" */).then(c => c.default || c),
  "close-white": () => import("/opt/build/repo/layers/ui/assets/icons/close-white.svg?raw" /* webpackChunkName: "assets/icons/close-white" */).then(c => c.default || c),
  "close": () => import("/opt/build/repo/layers/ui/assets/icons/close.svg?raw" /* webpackChunkName: "assets/icons/close" */).then(c => c.default || c),
  "collapse": () => import("/opt/build/repo/layers/ui/assets/icons/collapse.svg?raw" /* webpackChunkName: "assets/icons/collapse" */).then(c => c.default || c),
  "copy-clipboard": () => import("/opt/build/repo/layers/ui/assets/icons/copy-clipboard.svg?raw" /* webpackChunkName: "assets/icons/copy-clipboard" */).then(c => c.default || c),
  "crash": () => import("/opt/build/repo/layers/ui/assets/icons/crash.svg?raw" /* webpackChunkName: "assets/icons/crash" */).then(c => c.default || c),
  "currency-dollar-mediun-square": () => import("/opt/build/repo/layers/ui/assets/icons/currency-dollar-mediun-square.svg?raw" /* webpackChunkName: "assets/icons/currency-dollar-mediun-square" */).then(c => c.default || c),
  "customize": () => import("/opt/build/repo/layers/ui/assets/icons/customize.svg?raw" /* webpackChunkName: "assets/icons/customize" */).then(c => c.default || c),
  "document-clip": () => import("/opt/build/repo/layers/ui/assets/icons/document-clip.svg?raw" /* webpackChunkName: "assets/icons/document-clip" */).then(c => c.default || c),
  "document-clock": () => import("/opt/build/repo/layers/ui/assets/icons/document-clock.svg?raw" /* webpackChunkName: "assets/icons/document-clock" */).then(c => c.default || c),
  "document-do-list": () => import("/opt/build/repo/layers/ui/assets/icons/document-do-list.svg?raw" /* webpackChunkName: "assets/icons/document-do-list" */).then(c => c.default || c),
  "document-edit-2": () => import("/opt/build/repo/layers/ui/assets/icons/document-edit-2.svg?raw" /* webpackChunkName: "assets/icons/document-edit-2" */).then(c => c.default || c),
  "document-edit": () => import("/opt/build/repo/layers/ui/assets/icons/document-edit.svg?raw" /* webpackChunkName: "assets/icons/document-edit" */).then(c => c.default || c),
  "document-excel": () => import("/opt/build/repo/layers/ui/assets/icons/document-excel.svg?raw" /* webpackChunkName: "assets/icons/document-excel" */).then(c => c.default || c),
  "document-file-clip": () => import("/opt/build/repo/layers/ui/assets/icons/document-file-clip.svg?raw" /* webpackChunkName: "assets/icons/document-file-clip" */).then(c => c.default || c),
  "document-file-download-arrow-down": () => import("/opt/build/repo/layers/ui/assets/icons/document-file-download-arrow-down.svg?raw" /* webpackChunkName: "assets/icons/document-file-download-arrow-down" */).then(c => c.default || c),
  "document-file-search-bottom-left": () => import("/opt/build/repo/layers/ui/assets/icons/document-file-search-bottom-left.svg?raw" /* webpackChunkName: "assets/icons/document-file-search-bottom-left" */).then(c => c.default || c),
  "document-question": () => import("/opt/build/repo/layers/ui/assets/icons/document-question.svg?raw" /* webpackChunkName: "assets/icons/document-question" */).then(c => c.default || c),
  "document-search": () => import("/opt/build/repo/layers/ui/assets/icons/document-search.svg?raw" /* webpackChunkName: "assets/icons/document-search" */).then(c => c.default || c),
  "documents-file-clock": () => import("/opt/build/repo/layers/ui/assets/icons/documents-file-clock.svg?raw" /* webpackChunkName: "assets/icons/documents-file-clock" */).then(c => c.default || c),
  "documents-file-text": () => import("/opt/build/repo/layers/ui/assets/icons/documents-file-text.svg?raw" /* webpackChunkName: "assets/icons/documents-file-text" */).then(c => c.default || c),
  "documents-warning": () => import("/opt/build/repo/layers/ui/assets/icons/documents-warning.svg?raw" /* webpackChunkName: "assets/icons/documents-warning" */).then(c => c.default || c),
  "dots-grid": () => import("/opt/build/repo/layers/ui/assets/icons/dots-grid.svg?raw" /* webpackChunkName: "assets/icons/dots-grid" */).then(c => c.default || c),
  "download-arrow": () => import("/opt/build/repo/layers/ui/assets/icons/download-arrow.svg?raw" /* webpackChunkName: "assets/icons/download-arrow" */).then(c => c.default || c),
  "download": () => import("/opt/build/repo/layers/ui/assets/icons/download.svg?raw" /* webpackChunkName: "assets/icons/download" */).then(c => c.default || c),
  "edit-05": () => import("/opt/build/repo/layers/ui/assets/icons/edit-05.svg?raw" /* webpackChunkName: "assets/icons/edit-05" */).then(c => c.default || c),
  "edit-document": () => import("/opt/build/repo/layers/ui/assets/icons/edit-document.svg?raw" /* webpackChunkName: "assets/icons/edit-document" */).then(c => c.default || c),
  "edit": () => import("/opt/build/repo/layers/ui/assets/icons/edit.svg?raw" /* webpackChunkName: "assets/icons/edit" */).then(c => c.default || c),
  "email-edit": () => import("/opt/build/repo/layers/ui/assets/icons/email-edit.svg?raw" /* webpackChunkName: "assets/icons/email-edit" */).then(c => c.default || c),
  "empty-folder": () => import("/opt/build/repo/layers/ui/assets/icons/empty-folder.svg?raw" /* webpackChunkName: "assets/icons/empty-folder" */).then(c => c.default || c),
  "expand": () => import("/opt/build/repo/layers/ui/assets/icons/expand.svg?raw" /* webpackChunkName: "assets/icons/expand" */).then(c => c.default || c),
  "expiryDate": () => import("/opt/build/repo/layers/ui/assets/icons/expiryDate.svg?raw" /* webpackChunkName: "assets/icons/expiryDate" */).then(c => c.default || c),
  "eye": () => import("/opt/build/repo/layers/ui/assets/icons/eye.svg?raw" /* webpackChunkName: "assets/icons/eye" */).then(c => c.default || c),
  "factory": () => import("/opt/build/repo/layers/ui/assets/icons/factory.svg?raw" /* webpackChunkName: "assets/icons/factory" */).then(c => c.default || c),
  "file-06": () => import("/opt/build/repo/layers/ui/assets/icons/file-06.svg?raw" /* webpackChunkName: "assets/icons/file-06" */).then(c => c.default || c),
  "file-attachment": () => import("/opt/build/repo/layers/ui/assets/icons/file-attachment.svg?raw" /* webpackChunkName: "assets/icons/file-attachment" */).then(c => c.default || c),
  "file-csv": () => import("/opt/build/repo/layers/ui/assets/icons/file-csv.svg?raw" /* webpackChunkName: "assets/icons/file-csv" */).then(c => c.default || c),
  "file-download": () => import("/opt/build/repo/layers/ui/assets/icons/file-download.svg?raw" /* webpackChunkName: "assets/icons/file-download" */).then(c => c.default || c),
  "file-pdf": () => import("/opt/build/repo/layers/ui/assets/icons/file-pdf.svg?raw" /* webpackChunkName: "assets/icons/file-pdf" */).then(c => c.default || c),
  "file-report": () => import("/opt/build/repo/layers/ui/assets/icons/file-report.svg?raw" /* webpackChunkName: "assets/icons/file-report" */).then(c => c.default || c),
  "filter-lines": () => import("/opt/build/repo/layers/ui/assets/icons/filter-lines.svg?raw" /* webpackChunkName: "assets/icons/filter-lines" */).then(c => c.default || c),
  "filter": () => import("/opt/build/repo/layers/ui/assets/icons/filter.svg?raw" /* webpackChunkName: "assets/icons/filter" */).then(c => c.default || c),
  "flags-1x1/ac": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/ac.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/ac" */).then(c => c.default || c),
  "flags-1x1/ad": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/ad.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/ad" */).then(c => c.default || c),
  "flags-1x1/ae": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/ae.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/ae" */).then(c => c.default || c),
  "flags-1x1/af": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/af.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/af" */).then(c => c.default || c),
  "flags-1x1/ag": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/ag.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/ag" */).then(c => c.default || c),
  "flags-1x1/ai": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/ai.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/ai" */).then(c => c.default || c),
  "flags-1x1/al": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/al.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/al" */).then(c => c.default || c),
  "flags-1x1/am": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/am.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/am" */).then(c => c.default || c),
  "flags-1x1/ao": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/ao.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/ao" */).then(c => c.default || c),
  "flags-1x1/aq": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/aq.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/aq" */).then(c => c.default || c),
  "flags-1x1/ar": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/ar.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/ar" */).then(c => c.default || c),
  "flags-1x1/arab": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/arab.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/arab" */).then(c => c.default || c),
  "flags-1x1/as": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/as.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/as" */).then(c => c.default || c),
  "flags-1x1/at": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/at.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/at" */).then(c => c.default || c),
  "flags-1x1/au": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/au.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/au" */).then(c => c.default || c),
  "flags-1x1/aw": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/aw.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/aw" */).then(c => c.default || c),
  "flags-1x1/ax": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/ax.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/ax" */).then(c => c.default || c),
  "flags-1x1/az": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/az.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/az" */).then(c => c.default || c),
  "flags-1x1/ba": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/ba.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/ba" */).then(c => c.default || c),
  "flags-1x1/bb": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/bb.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/bb" */).then(c => c.default || c),
  "flags-1x1/bd": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/bd.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/bd" */).then(c => c.default || c),
  "flags-1x1/be": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/be.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/be" */).then(c => c.default || c),
  "flags-1x1/bf": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/bf.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/bf" */).then(c => c.default || c),
  "flags-1x1/bg": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/bg.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/bg" */).then(c => c.default || c),
  "flags-1x1/bh": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/bh.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/bh" */).then(c => c.default || c),
  "flags-1x1/bi": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/bi.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/bi" */).then(c => c.default || c),
  "flags-1x1/bj": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/bj.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/bj" */).then(c => c.default || c),
  "flags-1x1/bl": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/bl.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/bl" */).then(c => c.default || c),
  "flags-1x1/bm": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/bm.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/bm" */).then(c => c.default || c),
  "flags-1x1/bn": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/bn.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/bn" */).then(c => c.default || c),
  "flags-1x1/bo": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/bo.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/bo" */).then(c => c.default || c),
  "flags-1x1/bq": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/bq.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/bq" */).then(c => c.default || c),
  "flags-1x1/br": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/br.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/br" */).then(c => c.default || c),
  "flags-1x1/bs": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/bs.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/bs" */).then(c => c.default || c),
  "flags-1x1/bt": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/bt.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/bt" */).then(c => c.default || c),
  "flags-1x1/bv": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/bv.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/bv" */).then(c => c.default || c),
  "flags-1x1/bw": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/bw.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/bw" */).then(c => c.default || c),
  "flags-1x1/by": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/by.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/by" */).then(c => c.default || c),
  "flags-1x1/bz": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/bz.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/bz" */).then(c => c.default || c),
  "flags-1x1/ca": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/ca.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/ca" */).then(c => c.default || c),
  "flags-1x1/cc": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/cc.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/cc" */).then(c => c.default || c),
  "flags-1x1/cd": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/cd.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/cd" */).then(c => c.default || c),
  "flags-1x1/cefta": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/cefta.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/cefta" */).then(c => c.default || c),
  "flags-1x1/cf": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/cf.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/cf" */).then(c => c.default || c),
  "flags-1x1/cg": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/cg.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/cg" */).then(c => c.default || c),
  "flags-1x1/ch": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/ch.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/ch" */).then(c => c.default || c),
  "flags-1x1/ci": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/ci.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/ci" */).then(c => c.default || c),
  "flags-1x1/ck": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/ck.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/ck" */).then(c => c.default || c),
  "flags-1x1/cl": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/cl.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/cl" */).then(c => c.default || c),
  "flags-1x1/cm": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/cm.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/cm" */).then(c => c.default || c),
  "flags-1x1/cn": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/cn.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/cn" */).then(c => c.default || c),
  "flags-1x1/co": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/co.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/co" */).then(c => c.default || c),
  "flags-1x1/cp": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/cp.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/cp" */).then(c => c.default || c),
  "flags-1x1/cr": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/cr.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/cr" */).then(c => c.default || c),
  "flags-1x1/cu": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/cu.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/cu" */).then(c => c.default || c),
  "flags-1x1/cv": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/cv.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/cv" */).then(c => c.default || c),
  "flags-1x1/cw": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/cw.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/cw" */).then(c => c.default || c),
  "flags-1x1/cx": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/cx.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/cx" */).then(c => c.default || c),
  "flags-1x1/cy": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/cy.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/cy" */).then(c => c.default || c),
  "flags-1x1/cz": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/cz.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/cz" */).then(c => c.default || c),
  "flags-1x1/de": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/de.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/de" */).then(c => c.default || c),
  "flags-1x1/dg": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/dg.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/dg" */).then(c => c.default || c),
  "flags-1x1/dj": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/dj.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/dj" */).then(c => c.default || c),
  "flags-1x1/dk": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/dk.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/dk" */).then(c => c.default || c),
  "flags-1x1/dm": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/dm.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/dm" */).then(c => c.default || c),
  "flags-1x1/do": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/do.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/do" */).then(c => c.default || c),
  "flags-1x1/dz": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/dz.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/dz" */).then(c => c.default || c),
  "flags-1x1/eac": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/eac.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/eac" */).then(c => c.default || c),
  "flags-1x1/ec": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/ec.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/ec" */).then(c => c.default || c),
  "flags-1x1/ee": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/ee.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/ee" */).then(c => c.default || c),
  "flags-1x1/eg": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/eg.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/eg" */).then(c => c.default || c),
  "flags-1x1/eh": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/eh.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/eh" */).then(c => c.default || c),
  "flags-1x1/er": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/er.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/er" */).then(c => c.default || c),
  "flags-1x1/es-ct": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/es-ct.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/es-ct" */).then(c => c.default || c),
  "flags-1x1/es-ga": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/es-ga.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/es-ga" */).then(c => c.default || c),
  "flags-1x1/es-pv": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/es-pv.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/es-pv" */).then(c => c.default || c),
  "flags-1x1/es": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/es.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/es" */).then(c => c.default || c),
  "flags-1x1/et": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/et.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/et" */).then(c => c.default || c),
  "flags-1x1/eu": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/eu.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/eu" */).then(c => c.default || c),
  "flags-1x1/fi": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/fi.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/fi" */).then(c => c.default || c),
  "flags-1x1/fj": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/fj.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/fj" */).then(c => c.default || c),
  "flags-1x1/fk": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/fk.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/fk" */).then(c => c.default || c),
  "flags-1x1/fm": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/fm.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/fm" */).then(c => c.default || c),
  "flags-1x1/fo": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/fo.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/fo" */).then(c => c.default || c),
  "flags-1x1/fr": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/fr.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/fr" */).then(c => c.default || c),
  "flags-1x1/ga": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/ga.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/ga" */).then(c => c.default || c),
  "flags-1x1/gb-eng": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/gb-eng.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/gb-eng" */).then(c => c.default || c),
  "flags-1x1/gb-nir": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/gb-nir.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/gb-nir" */).then(c => c.default || c),
  "flags-1x1/gb-sct": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/gb-sct.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/gb-sct" */).then(c => c.default || c),
  "flags-1x1/gb-wls": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/gb-wls.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/gb-wls" */).then(c => c.default || c),
  "flags-1x1/gb": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/gb.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/gb" */).then(c => c.default || c),
  "flags-1x1/gd": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/gd.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/gd" */).then(c => c.default || c),
  "flags-1x1/ge": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/ge.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/ge" */).then(c => c.default || c),
  "flags-1x1/gf": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/gf.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/gf" */).then(c => c.default || c),
  "flags-1x1/gg": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/gg.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/gg" */).then(c => c.default || c),
  "flags-1x1/gh": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/gh.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/gh" */).then(c => c.default || c),
  "flags-1x1/gi": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/gi.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/gi" */).then(c => c.default || c),
  "flags-1x1/gl": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/gl.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/gl" */).then(c => c.default || c),
  "flags-1x1/gm": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/gm.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/gm" */).then(c => c.default || c),
  "flags-1x1/gn": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/gn.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/gn" */).then(c => c.default || c),
  "flags-1x1/gp": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/gp.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/gp" */).then(c => c.default || c),
  "flags-1x1/gq": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/gq.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/gq" */).then(c => c.default || c),
  "flags-1x1/gr": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/gr.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/gr" */).then(c => c.default || c),
  "flags-1x1/gs": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/gs.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/gs" */).then(c => c.default || c),
  "flags-1x1/gt": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/gt.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/gt" */).then(c => c.default || c),
  "flags-1x1/gu": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/gu.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/gu" */).then(c => c.default || c),
  "flags-1x1/gw": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/gw.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/gw" */).then(c => c.default || c),
  "flags-1x1/gy": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/gy.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/gy" */).then(c => c.default || c),
  "flags-1x1/hk": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/hk.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/hk" */).then(c => c.default || c),
  "flags-1x1/hm": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/hm.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/hm" */).then(c => c.default || c),
  "flags-1x1/hn": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/hn.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/hn" */).then(c => c.default || c),
  "flags-1x1/hr": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/hr.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/hr" */).then(c => c.default || c),
  "flags-1x1/ht": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/ht.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/ht" */).then(c => c.default || c),
  "flags-1x1/hu": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/hu.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/hu" */).then(c => c.default || c),
  "flags-1x1/ic": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/ic.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/ic" */).then(c => c.default || c),
  "flags-1x1/id": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/id.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/id" */).then(c => c.default || c),
  "flags-1x1/ie": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/ie.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/ie" */).then(c => c.default || c),
  "flags-1x1/il": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/il.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/il" */).then(c => c.default || c),
  "flags-1x1/im": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/im.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/im" */).then(c => c.default || c),
  "flags-1x1/in": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/in.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/in" */).then(c => c.default || c),
  "flags-1x1/io": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/io.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/io" */).then(c => c.default || c),
  "flags-1x1/iq": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/iq.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/iq" */).then(c => c.default || c),
  "flags-1x1/ir": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/ir.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/ir" */).then(c => c.default || c),
  "flags-1x1/is": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/is.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/is" */).then(c => c.default || c),
  "flags-1x1/it": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/it.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/it" */).then(c => c.default || c),
  "flags-1x1/je": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/je.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/je" */).then(c => c.default || c),
  "flags-1x1/jm": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/jm.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/jm" */).then(c => c.default || c),
  "flags-1x1/jo": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/jo.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/jo" */).then(c => c.default || c),
  "flags-1x1/jp": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/jp.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/jp" */).then(c => c.default || c),
  "flags-1x1/ke": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/ke.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/ke" */).then(c => c.default || c),
  "flags-1x1/kg": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/kg.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/kg" */).then(c => c.default || c),
  "flags-1x1/kh": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/kh.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/kh" */).then(c => c.default || c),
  "flags-1x1/ki": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/ki.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/ki" */).then(c => c.default || c),
  "flags-1x1/km": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/km.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/km" */).then(c => c.default || c),
  "flags-1x1/kn": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/kn.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/kn" */).then(c => c.default || c),
  "flags-1x1/kp": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/kp.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/kp" */).then(c => c.default || c),
  "flags-1x1/kr": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/kr.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/kr" */).then(c => c.default || c),
  "flags-1x1/kw": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/kw.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/kw" */).then(c => c.default || c),
  "flags-1x1/ky": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/ky.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/ky" */).then(c => c.default || c),
  "flags-1x1/kz": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/kz.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/kz" */).then(c => c.default || c),
  "flags-1x1/la": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/la.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/la" */).then(c => c.default || c),
  "flags-1x1/lb": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/lb.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/lb" */).then(c => c.default || c),
  "flags-1x1/lc": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/lc.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/lc" */).then(c => c.default || c),
  "flags-1x1/li": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/li.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/li" */).then(c => c.default || c),
  "flags-1x1/lk": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/lk.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/lk" */).then(c => c.default || c),
  "flags-1x1/lr": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/lr.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/lr" */).then(c => c.default || c),
  "flags-1x1/ls": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/ls.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/ls" */).then(c => c.default || c),
  "flags-1x1/lt": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/lt.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/lt" */).then(c => c.default || c),
  "flags-1x1/lu": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/lu.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/lu" */).then(c => c.default || c),
  "flags-1x1/lv": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/lv.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/lv" */).then(c => c.default || c),
  "flags-1x1/ly": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/ly.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/ly" */).then(c => c.default || c),
  "flags-1x1/ma": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/ma.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/ma" */).then(c => c.default || c),
  "flags-1x1/mc": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/mc.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/mc" */).then(c => c.default || c),
  "flags-1x1/md": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/md.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/md" */).then(c => c.default || c),
  "flags-1x1/me": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/me.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/me" */).then(c => c.default || c),
  "flags-1x1/mf": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/mf.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/mf" */).then(c => c.default || c),
  "flags-1x1/mg": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/mg.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/mg" */).then(c => c.default || c),
  "flags-1x1/mh": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/mh.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/mh" */).then(c => c.default || c),
  "flags-1x1/mk": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/mk.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/mk" */).then(c => c.default || c),
  "flags-1x1/ml": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/ml.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/ml" */).then(c => c.default || c),
  "flags-1x1/mm": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/mm.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/mm" */).then(c => c.default || c),
  "flags-1x1/mn": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/mn.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/mn" */).then(c => c.default || c),
  "flags-1x1/mo": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/mo.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/mo" */).then(c => c.default || c),
  "flags-1x1/mp": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/mp.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/mp" */).then(c => c.default || c),
  "flags-1x1/mq": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/mq.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/mq" */).then(c => c.default || c),
  "flags-1x1/mr": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/mr.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/mr" */).then(c => c.default || c),
  "flags-1x1/ms": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/ms.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/ms" */).then(c => c.default || c),
  "flags-1x1/mt": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/mt.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/mt" */).then(c => c.default || c),
  "flags-1x1/mu": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/mu.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/mu" */).then(c => c.default || c),
  "flags-1x1/mv": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/mv.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/mv" */).then(c => c.default || c),
  "flags-1x1/mw": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/mw.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/mw" */).then(c => c.default || c),
  "flags-1x1/mx": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/mx.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/mx" */).then(c => c.default || c),
  "flags-1x1/my": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/my.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/my" */).then(c => c.default || c),
  "flags-1x1/mz": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/mz.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/mz" */).then(c => c.default || c),
  "flags-1x1/na": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/na.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/na" */).then(c => c.default || c),
  "flags-1x1/nc": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/nc.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/nc" */).then(c => c.default || c),
  "flags-1x1/ne": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/ne.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/ne" */).then(c => c.default || c),
  "flags-1x1/nf": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/nf.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/nf" */).then(c => c.default || c),
  "flags-1x1/ng": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/ng.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/ng" */).then(c => c.default || c),
  "flags-1x1/ni": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/ni.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/ni" */).then(c => c.default || c),
  "flags-1x1/nl": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/nl.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/nl" */).then(c => c.default || c),
  "flags-1x1/no": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/no.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/no" */).then(c => c.default || c),
  "flags-1x1/np": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/np.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/np" */).then(c => c.default || c),
  "flags-1x1/nr": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/nr.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/nr" */).then(c => c.default || c),
  "flags-1x1/nu": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/nu.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/nu" */).then(c => c.default || c),
  "flags-1x1/nz": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/nz.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/nz" */).then(c => c.default || c),
  "flags-1x1/om": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/om.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/om" */).then(c => c.default || c),
  "flags-1x1/pa": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/pa.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/pa" */).then(c => c.default || c),
  "flags-1x1/pe": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/pe.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/pe" */).then(c => c.default || c),
  "flags-1x1/pf": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/pf.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/pf" */).then(c => c.default || c),
  "flags-1x1/pg": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/pg.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/pg" */).then(c => c.default || c),
  "flags-1x1/ph": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/ph.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/ph" */).then(c => c.default || c),
  "flags-1x1/pk": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/pk.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/pk" */).then(c => c.default || c),
  "flags-1x1/pl": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/pl.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/pl" */).then(c => c.default || c),
  "flags-1x1/pm": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/pm.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/pm" */).then(c => c.default || c),
  "flags-1x1/pn": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/pn.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/pn" */).then(c => c.default || c),
  "flags-1x1/pr": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/pr.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/pr" */).then(c => c.default || c),
  "flags-1x1/ps": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/ps.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/ps" */).then(c => c.default || c),
  "flags-1x1/pt": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/pt.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/pt" */).then(c => c.default || c),
  "flags-1x1/pw": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/pw.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/pw" */).then(c => c.default || c),
  "flags-1x1/py": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/py.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/py" */).then(c => c.default || c),
  "flags-1x1/qa": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/qa.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/qa" */).then(c => c.default || c),
  "flags-1x1/re": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/re.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/re" */).then(c => c.default || c),
  "flags-1x1/ro": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/ro.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/ro" */).then(c => c.default || c),
  "flags-1x1/rs": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/rs.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/rs" */).then(c => c.default || c),
  "flags-1x1/ru": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/ru.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/ru" */).then(c => c.default || c),
  "flags-1x1/rw": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/rw.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/rw" */).then(c => c.default || c),
  "flags-1x1/sa": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/sa.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/sa" */).then(c => c.default || c),
  "flags-1x1/sb": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/sb.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/sb" */).then(c => c.default || c),
  "flags-1x1/sc": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/sc.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/sc" */).then(c => c.default || c),
  "flags-1x1/sd": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/sd.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/sd" */).then(c => c.default || c),
  "flags-1x1/se": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/se.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/se" */).then(c => c.default || c),
  "flags-1x1/sg": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/sg.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/sg" */).then(c => c.default || c),
  "flags-1x1/sh": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/sh.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/sh" */).then(c => c.default || c),
  "flags-1x1/si": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/si.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/si" */).then(c => c.default || c),
  "flags-1x1/sj": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/sj.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/sj" */).then(c => c.default || c),
  "flags-1x1/sk": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/sk.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/sk" */).then(c => c.default || c),
  "flags-1x1/sl": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/sl.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/sl" */).then(c => c.default || c),
  "flags-1x1/sm": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/sm.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/sm" */).then(c => c.default || c),
  "flags-1x1/sn": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/sn.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/sn" */).then(c => c.default || c),
  "flags-1x1/so": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/so.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/so" */).then(c => c.default || c),
  "flags-1x1/sr": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/sr.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/sr" */).then(c => c.default || c),
  "flags-1x1/ss": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/ss.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/ss" */).then(c => c.default || c),
  "flags-1x1/st": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/st.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/st" */).then(c => c.default || c),
  "flags-1x1/sv": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/sv.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/sv" */).then(c => c.default || c),
  "flags-1x1/sx": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/sx.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/sx" */).then(c => c.default || c),
  "flags-1x1/sy": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/sy.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/sy" */).then(c => c.default || c),
  "flags-1x1/sz": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/sz.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/sz" */).then(c => c.default || c),
  "flags-1x1/ta": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/ta.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/ta" */).then(c => c.default || c),
  "flags-1x1/tc": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/tc.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/tc" */).then(c => c.default || c),
  "flags-1x1/td": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/td.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/td" */).then(c => c.default || c),
  "flags-1x1/tf": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/tf.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/tf" */).then(c => c.default || c),
  "flags-1x1/tg": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/tg.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/tg" */).then(c => c.default || c),
  "flags-1x1/th": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/th.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/th" */).then(c => c.default || c),
  "flags-1x1/tj": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/tj.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/tj" */).then(c => c.default || c),
  "flags-1x1/tk": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/tk.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/tk" */).then(c => c.default || c),
  "flags-1x1/tl": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/tl.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/tl" */).then(c => c.default || c),
  "flags-1x1/tm": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/tm.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/tm" */).then(c => c.default || c),
  "flags-1x1/tn": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/tn.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/tn" */).then(c => c.default || c),
  "flags-1x1/to": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/to.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/to" */).then(c => c.default || c),
  "flags-1x1/tr": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/tr.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/tr" */).then(c => c.default || c),
  "flags-1x1/tt": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/tt.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/tt" */).then(c => c.default || c),
  "flags-1x1/tv": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/tv.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/tv" */).then(c => c.default || c),
  "flags-1x1/tw": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/tw.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/tw" */).then(c => c.default || c),
  "flags-1x1/tz": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/tz.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/tz" */).then(c => c.default || c),
  "flags-1x1/ua": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/ua.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/ua" */).then(c => c.default || c),
  "flags-1x1/ug": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/ug.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/ug" */).then(c => c.default || c),
  "flags-1x1/um": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/um.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/um" */).then(c => c.default || c),
  "flags-1x1/un": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/un.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/un" */).then(c => c.default || c),
  "flags-1x1/us": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/us.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/us" */).then(c => c.default || c),
  "flags-1x1/uy": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/uy.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/uy" */).then(c => c.default || c),
  "flags-1x1/uz": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/uz.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/uz" */).then(c => c.default || c),
  "flags-1x1/va": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/va.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/va" */).then(c => c.default || c),
  "flags-1x1/vc": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/vc.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/vc" */).then(c => c.default || c),
  "flags-1x1/ve": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/ve.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/ve" */).then(c => c.default || c),
  "flags-1x1/vg": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/vg.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/vg" */).then(c => c.default || c),
  "flags-1x1/vi": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/vi.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/vi" */).then(c => c.default || c),
  "flags-1x1/vn": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/vn.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/vn" */).then(c => c.default || c),
  "flags-1x1/vu": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/vu.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/vu" */).then(c => c.default || c),
  "flags-1x1/wf": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/wf.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/wf" */).then(c => c.default || c),
  "flags-1x1/ws": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/ws.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/ws" */).then(c => c.default || c),
  "flags-1x1/xk": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/xk.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/xk" */).then(c => c.default || c),
  "flags-1x1/xx": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/xx.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/xx" */).then(c => c.default || c),
  "flags-1x1/ye": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/ye.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/ye" */).then(c => c.default || c),
  "flags-1x1/yt": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/yt.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/yt" */).then(c => c.default || c),
  "flags-1x1/za": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/za.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/za" */).then(c => c.default || c),
  "flags-1x1/zm": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/zm.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/zm" */).then(c => c.default || c),
  "flags-1x1/zw": () => import("/opt/build/repo/layers/ui/assets/icons/flags-1x1/zw.svg?raw" /* webpackChunkName: "assets/icons/flags-1x1/zw" */).then(c => c.default || c),
  "folder-add-plus-left": () => import("/opt/build/repo/layers/ui/assets/icons/folder-add-plus-left.svg?raw" /* webpackChunkName: "assets/icons/folder-add-plus-left" */).then(c => c.default || c),
  "folder-file-text-group": () => import("/opt/build/repo/layers/ui/assets/icons/folder-file-text-group.svg?raw" /* webpackChunkName: "assets/icons/folder-file-text-group" */).then(c => c.default || c),
  "folder-group-copy": () => import("/opt/build/repo/layers/ui/assets/icons/folder-group-copy.svg?raw" /* webpackChunkName: "assets/icons/folder-group-copy" */).then(c => c.default || c),
  "folder": () => import("/opt/build/repo/layers/ui/assets/icons/folder.svg?raw" /* webpackChunkName: "assets/icons/folder" */).then(c => c.default || c),
  "fuel": () => import("/opt/build/repo/layers/ui/assets/icons/fuel.svg?raw" /* webpackChunkName: "assets/icons/fuel" */).then(c => c.default || c),
  "headlease": () => import("/opt/build/repo/layers/ui/assets/icons/headlease.svg?raw" /* webpackChunkName: "assets/icons/headlease" */).then(c => c.default || c),
  "home": () => import("/opt/build/repo/layers/ui/assets/icons/home.svg?raw" /* webpackChunkName: "assets/icons/home" */).then(c => c.default || c),
  "house-document": () => import("/opt/build/repo/layers/ui/assets/icons/house-document.svg?raw" /* webpackChunkName: "assets/icons/house-document" */).then(c => c.default || c),
  "house-location-pin": () => import("/opt/build/repo/layers/ui/assets/icons/house-location-pin.svg?raw" /* webpackChunkName: "assets/icons/house-location-pin" */).then(c => c.default || c),
  "ic_cloud": () => import("/opt/build/repo/layers/ui/assets/icons/ic_cloud.svg?raw" /* webpackChunkName: "assets/icons/ic_cloud" */).then(c => c.default || c),
  "icon-upload-cloud": () => import("/opt/build/repo/layers/ui/assets/icons/icon-upload-cloud.svg?raw" /* webpackChunkName: "assets/icons/icon-upload-cloud" */).then(c => c.default || c),
  "info": () => import("/opt/build/repo/layers/ui/assets/icons/info.svg?raw" /* webpackChunkName: "assets/icons/info" */).then(c => c.default || c),
  "kebab-menu": () => import("/opt/build/repo/layers/ui/assets/icons/kebab-menu.svg?raw" /* webpackChunkName: "assets/icons/kebab-menu" */).then(c => c.default || c),
  "letter-document": () => import("/opt/build/repo/layers/ui/assets/icons/letter-document.svg?raw" /* webpackChunkName: "assets/icons/letter-document" */).then(c => c.default || c),
  "link-01": () => import("/opt/build/repo/layers/ui/assets/icons/link-01.svg?raw" /* webpackChunkName: "assets/icons/link-01" */).then(c => c.default || c),
  "link-02": () => import("/opt/build/repo/layers/ui/assets/icons/link-02.svg?raw" /* webpackChunkName: "assets/icons/link-02" */).then(c => c.default || c),
  "link-03": () => import("/opt/build/repo/layers/ui/assets/icons/link-03.svg?raw" /* webpackChunkName: "assets/icons/link-03" */).then(c => c.default || c),
  "link-04": () => import("/opt/build/repo/layers/ui/assets/icons/link-04.svg?raw" /* webpackChunkName: "assets/icons/link-04" */).then(c => c.default || c),
  "link-05": () => import("/opt/build/repo/layers/ui/assets/icons/link-05.svg?raw" /* webpackChunkName: "assets/icons/link-05" */).then(c => c.default || c),
  "link": () => import("/opt/build/repo/layers/ui/assets/icons/link.svg?raw" /* webpackChunkName: "assets/icons/link" */).then(c => c.default || c),
  "logout": () => import("/opt/build/repo/layers/ui/assets/icons/logout.svg?raw" /* webpackChunkName: "assets/icons/logout" */).then(c => c.default || c),
  "mailbox-download": () => import("/opt/build/repo/layers/ui/assets/icons/mailbox-download.svg?raw" /* webpackChunkName: "assets/icons/mailbox-download" */).then(c => c.default || c),
  "map-house-home": () => import("/opt/build/repo/layers/ui/assets/icons/map-house-home.svg?raw" /* webpackChunkName: "assets/icons/map-house-home" */).then(c => c.default || c),
  "marketRentReview": () => import("/opt/build/repo/layers/ui/assets/icons/marketRentReview.svg?raw" /* webpackChunkName: "assets/icons/marketRentReview" */).then(c => c.default || c),
  "minus": () => import("/opt/build/repo/layers/ui/assets/icons/minus.svg?raw" /* webpackChunkName: "assets/icons/minus" */).then(c => c.default || c),
  "no-lease": () => import("/opt/build/repo/layers/ui/assets/icons/no-lease.svg?raw" /* webpackChunkName: "assets/icons/no-lease" */).then(c => c.default || c),
  "notification": () => import("/opt/build/repo/layers/ui/assets/icons/notification.svg?raw" /* webpackChunkName: "assets/icons/notification" */).then(c => c.default || c),
  "optionEnd": () => import("/opt/build/repo/layers/ui/assets/icons/optionEnd.svg?raw" /* webpackChunkName: "assets/icons/optionEnd" */).then(c => c.default || c),
  "optionStart": () => import("/opt/build/repo/layers/ui/assets/icons/optionStart.svg?raw" /* webpackChunkName: "assets/icons/optionStart" */).then(c => c.default || c),
  "pencil-alt": () => import("/opt/build/repo/layers/ui/assets/icons/pencil-alt.svg?raw" /* webpackChunkName: "assets/icons/pencil-alt" */).then(c => c.default || c),
  "pin-checkmark-circle": () => import("/opt/build/repo/layers/ui/assets/icons/pin-checkmark-circle.svg?raw" /* webpackChunkName: "assets/icons/pin-checkmark-circle" */).then(c => c.default || c),
  "pinned": () => import("/opt/build/repo/layers/ui/assets/icons/pinned.svg?raw" /* webpackChunkName: "assets/icons/pinned" */).then(c => c.default || c),
  "plus": () => import("/opt/build/repo/layers/ui/assets/icons/plus.svg?raw" /* webpackChunkName: "assets/icons/plus" */).then(c => c.default || c),
  "print": () => import("/opt/build/repo/layers/ui/assets/icons/print.svg?raw" /* webpackChunkName: "assets/icons/print" */).then(c => c.default || c),
  "printer": () => import("/opt/build/repo/layers/ui/assets/icons/printer.svg?raw" /* webpackChunkName: "assets/icons/printer" */).then(c => c.default || c),
  "question-select": () => import("/opt/build/repo/layers/ui/assets/icons/question-select.svg?raw" /* webpackChunkName: "assets/icons/question-select" */).then(c => c.default || c),
  "redo": () => import("/opt/build/repo/layers/ui/assets/icons/redo.svg?raw" /* webpackChunkName: "assets/icons/redo" */).then(c => c.default || c),
  "refresh-loading": () => import("/opt/build/repo/layers/ui/assets/icons/refresh-loading.svg?raw" /* webpackChunkName: "assets/icons/refresh-loading" */).then(c => c.default || c),
  "rentReview": () => import("/opt/build/repo/layers/ui/assets/icons/rentReview.svg?raw" /* webpackChunkName: "assets/icons/rentReview" */).then(c => c.default || c),
  "required": () => import("/opt/build/repo/layers/ui/assets/icons/required.svg?raw" /* webpackChunkName: "assets/icons/required" */).then(c => c.default || c),
  "review/Abatements": () => import("/opt/build/repo/layers/ui/assets/icons/review/Abatements.svg?raw" /* webpackChunkName: "assets/icons/review/Abatements" */).then(c => c.default || c),
  "review/AirConditioning": () => import("/opt/build/repo/layers/ui/assets/icons/review/AirConditioning.svg?raw" /* webpackChunkName: "assets/icons/review/AirConditioning" */).then(c => c.default || c),
  "review/Assignment": () => import("/opt/build/repo/layers/ui/assets/icons/review/Assignment.svg?raw" /* webpackChunkName: "assets/icons/review/Assignment" */).then(c => c.default || c),
  "review/BankGuarantee": () => import("/opt/build/repo/layers/ui/assets/icons/review/BankGuarantee.svg?raw" /* webpackChunkName: "assets/icons/review/BankGuarantee" */).then(c => c.default || c),
  "review/CAMOrOperatingExpense": () => import("/opt/build/repo/layers/ui/assets/icons/review/CAMOrOperatingExpense.svg?raw" /* webpackChunkName: "assets/icons/review/CAMOrOperatingExpense" */).then(c => c.default || c),
  "review/CPIReview": () => import("/opt/build/repo/layers/ui/assets/icons/review/CPIReview.svg?raw" /* webpackChunkName: "assets/icons/review/CPIReview" */).then(c => c.default || c),
  "review/CashIncentive": () => import("/opt/build/repo/layers/ui/assets/icons/review/CashIncentive.svg?raw" /* webpackChunkName: "assets/icons/review/CashIncentive" */).then(c => c.default || c),
  "review/ChangeOfControl": () => import("/opt/build/repo/layers/ui/assets/icons/review/ChangeOfControl.svg?raw" /* webpackChunkName: "assets/icons/review/ChangeOfControl" */).then(c => c.default || c),
  "review/ClausesAndDefinitions": () => import("/opt/build/repo/layers/ui/assets/icons/review/ClausesAndDefinitions.svg?raw" /* webpackChunkName: "assets/icons/review/ClausesAndDefinitions" */).then(c => c.default || c),
  "review/CommonAirConditioning": () => import("/opt/build/repo/layers/ui/assets/icons/review/CommonAirConditioning.svg?raw" /* webpackChunkName: "assets/icons/review/CommonAirConditioning" */).then(c => c.default || c),
  "review/Concessions": () => import("/opt/build/repo/layers/ui/assets/icons/review/Concessions.svg?raw" /* webpackChunkName: "assets/icons/review/Concessions" */).then(c => c.default || c),
  "review/Contamination": () => import("/opt/build/repo/layers/ui/assets/icons/review/Contamination.svg?raw" /* webpackChunkName: "assets/icons/review/Contamination" */).then(c => c.default || c),
  "review/DamageAndDestruction": () => import("/opt/build/repo/layers/ui/assets/icons/review/DamageAndDestruction.svg?raw" /* webpackChunkName: "assets/icons/review/DamageAndDestruction" */).then(c => c.default || c),
  "review/Demolition-1": () => import("/opt/build/repo/layers/ui/assets/icons/review/Demolition-1.svg?raw" /* webpackChunkName: "assets/icons/review/Demolition-1" */).then(c => c.default || c),
  "review/Demolition": () => import("/opt/build/repo/layers/ui/assets/icons/review/Demolition.svg?raw" /* webpackChunkName: "assets/icons/review/Demolition" */).then(c => c.default || c),
  "review/Deposits": () => import("/opt/build/repo/layers/ui/assets/icons/review/Deposits.svg?raw" /* webpackChunkName: "assets/icons/review/Deposits" */).then(c => c.default || c),
  "review/EndOfLease2": () => import("/opt/build/repo/layers/ui/assets/icons/review/EndOfLease2.svg?raw" /* webpackChunkName: "assets/icons/review/EndOfLease2" */).then(c => c.default || c),
  "review/EndOfLeaseObligations": () => import("/opt/build/repo/layers/ui/assets/icons/review/EndOfLeaseObligations.svg?raw" /* webpackChunkName: "assets/icons/review/EndOfLeaseObligations" */).then(c => c.default || c),
  "review/EssentialTerm": () => import("/opt/build/repo/layers/ui/assets/icons/review/EssentialTerm.svg?raw" /* webpackChunkName: "assets/icons/review/EssentialTerm" */).then(c => c.default || c),
  "review/EssentialTermClause": () => import("/opt/build/repo/layers/ui/assets/icons/review/EssentialTermClause.svg?raw" /* webpackChunkName: "assets/icons/review/EssentialTermClause" */).then(c => c.default || c),
  "review/EssentialTermDefinition": () => import("/opt/build/repo/layers/ui/assets/icons/review/EssentialTermDefinition.svg?raw" /* webpackChunkName: "assets/icons/review/EssentialTermDefinition" */).then(c => c.default || c),
  "review/Exclusivity": () => import("/opt/build/repo/layers/ui/assets/icons/review/Exclusivity.svg?raw" /* webpackChunkName: "assets/icons/review/Exclusivity" */).then(c => c.default || c),
  "review/FallbackClauseAndDefinitions": () => import("/opt/build/repo/layers/ui/assets/icons/review/FallbackClauseAndDefinitions.svg?raw" /* webpackChunkName: "assets/icons/review/FallbackClauseAndDefinitions" */).then(c => c.default || c),
  "review/FirstRightOfRefusal": () => import("/opt/build/repo/layers/ui/assets/icons/review/FirstRightOfRefusal.svg?raw" /* webpackChunkName: "assets/icons/review/FirstRightOfRefusal" */).then(c => c.default || c),
  "review/FitoutContribution": () => import("/opt/build/repo/layers/ui/assets/icons/review/FitoutContribution.svg?raw" /* webpackChunkName: "assets/icons/review/FitoutContribution" */).then(c => c.default || c),
  "review/FitoutsAndWorks": () => import("/opt/build/repo/layers/ui/assets/icons/review/FitoutsAndWorks.svg?raw" /* webpackChunkName: "assets/icons/review/FitoutsAndWorks" */).then(c => c.default || c),
  "review/Guarantor": () => import("/opt/build/repo/layers/ui/assets/icons/review/Guarantor.svg?raw" /* webpackChunkName: "assets/icons/review/Guarantor" */).then(c => c.default || c),
  "review/Holdover": () => import("/opt/build/repo/layers/ui/assets/icons/review/Holdover.svg?raw" /* webpackChunkName: "assets/icons/review/Holdover" */).then(c => c.default || c),
  "review/Incentives": () => import("/opt/build/repo/layers/ui/assets/icons/review/Incentives.svg?raw" /* webpackChunkName: "assets/icons/review/Incentives" */).then(c => c.default || c),
  "review/Insurance": () => import("/opt/build/repo/layers/ui/assets/icons/review/Insurance.svg?raw" /* webpackChunkName: "assets/icons/review/Insurance" */).then(c => c.default || c),
  "review/LeaseDates": () => import("/opt/build/repo/layers/ui/assets/icons/review/LeaseDates.svg?raw" /* webpackChunkName: "assets/icons/review/LeaseDates" */).then(c => c.default || c),
  "review/Lessee": () => import("/opt/build/repo/layers/ui/assets/icons/review/Lessee.svg?raw" /* webpackChunkName: "assets/icons/review/Lessee" */).then(c => c.default || c),
  "review/LesseeFixtures": () => import("/opt/build/repo/layers/ui/assets/icons/review/LesseeFixtures.svg?raw" /* webpackChunkName: "assets/icons/review/LesseeFixtures" */).then(c => c.default || c),
  "review/LesseeGroup": () => import("/opt/build/repo/layers/ui/assets/icons/review/LesseeGroup.svg?raw" /* webpackChunkName: "assets/icons/review/LesseeGroup" */).then(c => c.default || c),
  "review/LesseeRights": () => import("/opt/build/repo/layers/ui/assets/icons/review/LesseeRights.svg?raw" /* webpackChunkName: "assets/icons/review/LesseeRights" */).then(c => c.default || c),
  "review/LesseeRightsAndObligations": () => import("/opt/build/repo/layers/ui/assets/icons/review/LesseeRightsAndObligations.svg?raw" /* webpackChunkName: "assets/icons/review/LesseeRightsAndObligations" */).then(c => c.default || c),
  "review/Lessor": () => import("/opt/build/repo/layers/ui/assets/icons/review/Lessor.svg?raw" /* webpackChunkName: "assets/icons/review/Lessor" */).then(c => c.default || c),
  "review/LessorFixtures": () => import("/opt/build/repo/layers/ui/assets/icons/review/LessorFixtures.svg?raw" /* webpackChunkName: "assets/icons/review/LessorFixtures" */).then(c => c.default || c),
  "review/LessorRightsAndObligations": () => import("/opt/build/repo/layers/ui/assets/icons/review/LessorRightsAndObligations.svg?raw" /* webpackChunkName: "assets/icons/review/LessorRightsAndObligations" */).then(c => c.default || c),
  "review/Lessors": () => import("/opt/build/repo/layers/ui/assets/icons/review/Lessors.svg?raw" /* webpackChunkName: "assets/icons/review/Lessors" */).then(c => c.default || c),
  "review/LicencedArea": () => import("/opt/build/repo/layers/ui/assets/icons/review/LicencedArea.svg?raw" /* webpackChunkName: "assets/icons/review/LicencedArea" */).then(c => c.default || c),
  "review/MakeGood": () => import("/opt/build/repo/layers/ui/assets/icons/review/MakeGood.svg?raw" /* webpackChunkName: "assets/icons/review/MakeGood" */).then(c => c.default || c),
  "review/MakeGoodProvision": () => import("/opt/build/repo/layers/ui/assets/icons/review/MakeGoodProvision.svg?raw" /* webpackChunkName: "assets/icons/review/MakeGoodProvision" */).then(c => c.default || c),
  "review/MarketRentReview": () => import("/opt/build/repo/layers/ui/assets/icons/review/MarketRentReview.svg?raw" /* webpackChunkName: "assets/icons/review/MarketRentReview" */).then(c => c.default || c),
  "review/MarketRentReviews": () => import("/opt/build/repo/layers/ui/assets/icons/review/MarketRentReviews.svg?raw" /* webpackChunkName: "assets/icons/review/MarketRentReviews" */).then(c => c.default || c),
  "review/MarketReview": () => import("/opt/build/repo/layers/ui/assets/icons/review/MarketReview.svg?raw" /* webpackChunkName: "assets/icons/review/MarketReview" */).then(c => c.default || c),
  "review/MarketReviews": () => import("/opt/build/repo/layers/ui/assets/icons/review/MarketReviews.svg?raw" /* webpackChunkName: "assets/icons/review/MarketReviews" */).then(c => c.default || c),
  "review/MarketingLevy": () => import("/opt/build/repo/layers/ui/assets/icons/review/MarketingLevy.svg?raw" /* webpackChunkName: "assets/icons/review/MarketingLevy" */).then(c => c.default || c),
  "review/MarketingLevyIncreases": () => import("/opt/build/repo/layers/ui/assets/icons/review/MarketingLevyIncreases.svg?raw" /* webpackChunkName: "assets/icons/review/MarketingLevyIncreases" */).then(c => c.default || c),
  "review/OptionIncreases": () => import("/opt/build/repo/layers/ui/assets/icons/review/OptionIncreases.svg?raw" /* webpackChunkName: "assets/icons/review/OptionIncreases" */).then(c => c.default || c),
  "review/OptionLeaseDates": () => import("/opt/build/repo/layers/ui/assets/icons/review/OptionLeaseDates.svg?raw" /* webpackChunkName: "assets/icons/review/OptionLeaseDates" */).then(c => c.default || c),
  "review/Options": () => import("/opt/build/repo/layers/ui/assets/icons/review/Options.svg?raw" /* webpackChunkName: "assets/icons/review/Options" */).then(c => c.default || c),
  "review/Other": () => import("/opt/build/repo/layers/ui/assets/icons/review/Other.svg?raw" /* webpackChunkName: "assets/icons/review/Other" */).then(c => c.default || c),
  "review/OtherCharges": () => import("/opt/build/repo/layers/ui/assets/icons/review/OtherCharges.svg?raw" /* webpackChunkName: "assets/icons/review/OtherCharges" */).then(c => c.default || c),
  "review/OtherRecurringCharges": () => import("/opt/build/repo/layers/ui/assets/icons/review/OtherRecurringCharges.svg?raw" /* webpackChunkName: "assets/icons/review/OtherRecurringCharges" */).then(c => c.default || c),
  "review/OtherRentReview": () => import("/opt/build/repo/layers/ui/assets/icons/review/OtherRentReview.svg?raw" /* webpackChunkName: "assets/icons/review/OtherRentReview" */).then(c => c.default || c),
  "review/OtherTerms": () => import("/opt/build/repo/layers/ui/assets/icons/review/OtherTerms.svg?raw" /* webpackChunkName: "assets/icons/review/OtherTerms" */).then(c => c.default || c),
  "review/Outgoings": () => import("/opt/build/repo/layers/ui/assets/icons/review/Outgoings.svg?raw" /* webpackChunkName: "assets/icons/review/Outgoings" */).then(c => c.default || c),
  "review/OutgoingsAccountingPeriod": () => import("/opt/build/repo/layers/ui/assets/icons/review/OutgoingsAccountingPeriod.svg?raw" /* webpackChunkName: "assets/icons/review/OutgoingsAccountingPeriod" */).then(c => c.default || c),
  "review/OutgoingsObligations": () => import("/opt/build/repo/layers/ui/assets/icons/review/OutgoingsObligations.svg?raw" /* webpackChunkName: "assets/icons/review/OutgoingsObligations" */).then(c => c.default || c),
  "review/PaymentFrequency": () => import("/opt/build/repo/layers/ui/assets/icons/review/PaymentFrequency.svg?raw" /* webpackChunkName: "assets/icons/review/PaymentFrequency" */).then(c => c.default || c),
  "review/PermittedUse": () => import("/opt/build/repo/layers/ui/assets/icons/review/PermittedUse.svg?raw" /* webpackChunkName: "assets/icons/review/PermittedUse" */).then(c => c.default || c),
  "review/PropertyDetails": () => import("/opt/build/repo/layers/ui/assets/icons/review/PropertyDetails.svg?raw" /* webpackChunkName: "assets/icons/review/PropertyDetails" */).then(c => c.default || c),
  "review/PropertyManagerAgent": () => import("/opt/build/repo/layers/ui/assets/icons/review/PropertyManagerAgent.svg?raw" /* webpackChunkName: "assets/icons/review/PropertyManagerAgent" */).then(c => c.default || c),
  "review/PublicLiabilityInsurance": () => import("/opt/build/repo/layers/ui/assets/icons/review/PublicLiabilityInsurance.svg?raw" /* webpackChunkName: "assets/icons/review/PublicLiabilityInsurance" */).then(c => c.default || c),
  "review/RealEstateTaxes": () => import("/opt/build/repo/layers/ui/assets/icons/review/RealEstateTaxes.svg?raw" /* webpackChunkName: "assets/icons/review/RealEstateTaxes" */).then(c => c.default || c),
  "review/Redecoration": () => import("/opt/build/repo/layers/ui/assets/icons/review/Redecoration.svg?raw" /* webpackChunkName: "assets/icons/review/Redecoration" */).then(c => c.default || c),
  "review/Redevelopment": () => import("/opt/build/repo/layers/ui/assets/icons/review/Redevelopment.svg?raw" /* webpackChunkName: "assets/icons/review/Redevelopment" */).then(c => c.default || c),
  "review/Relocation": () => import("/opt/build/repo/layers/ui/assets/icons/review/Relocation.svg?raw" /* webpackChunkName: "assets/icons/review/Relocation" */).then(c => c.default || c),
  "review/Rent": () => import("/opt/build/repo/layers/ui/assets/icons/review/Rent.svg?raw" /* webpackChunkName: "assets/icons/review/Rent" */).then(c => c.default || c),
  "review/RentFreeIncentive": () => import("/opt/build/repo/layers/ui/assets/icons/review/RentFreeIncentive.svg?raw" /* webpackChunkName: "assets/icons/review/RentFreeIncentive" */).then(c => c.default || c),
  "review/RentIncreaseCpi": () => import("/opt/build/repo/layers/ui/assets/icons/review/RentIncreaseCpi.svg?raw" /* webpackChunkName: "assets/icons/review/RentIncreaseCpi" */).then(c => c.default || c),
  "review/RentIncreases": () => import("/opt/build/repo/layers/ui/assets/icons/review/RentIncreases.svg?raw" /* webpackChunkName: "assets/icons/review/RentIncreases" */).then(c => c.default || c),
  "review/RentInfo": () => import("/opt/build/repo/layers/ui/assets/icons/review/RentInfo.svg?raw" /* webpackChunkName: "assets/icons/review/RentInfo" */).then(c => c.default || c),
  "review/RepairingObligations": () => import("/opt/build/repo/layers/ui/assets/icons/review/RepairingObligations.svg?raw" /* webpackChunkName: "assets/icons/review/RepairingObligations" */).then(c => c.default || c),
  "review/RepairsAndMaintenance": () => import("/opt/build/repo/layers/ui/assets/icons/review/RepairsAndMaintenance.svg?raw" /* webpackChunkName: "assets/icons/review/RepairsAndMaintenance" */).then(c => c.default || c),
  "review/Security": () => import("/opt/build/repo/layers/ui/assets/icons/review/Security.svg?raw" /* webpackChunkName: "assets/icons/review/Security" */).then(c => c.default || c),
  "review/ServiceCharge": () => import("/opt/build/repo/layers/ui/assets/icons/review/ServiceCharge.svg?raw" /* webpackChunkName: "assets/icons/review/ServiceCharge" */).then(c => c.default || c),
  "review/SpecialConditions": () => import("/opt/build/repo/layers/ui/assets/icons/review/SpecialConditions.svg?raw" /* webpackChunkName: "assets/icons/review/SpecialConditions" */).then(c => c.default || c),
  "review/Termination": () => import("/opt/build/repo/layers/ui/assets/icons/review/Termination.svg?raw" /* webpackChunkName: "assets/icons/review/Termination" */).then(c => c.default || c),
  "review/TurnoverRent": () => import("/opt/build/repo/layers/ui/assets/icons/review/TurnoverRent.svg?raw" /* webpackChunkName: "assets/icons/review/TurnoverRent" */).then(c => c.default || c),
  "review/Use": () => import("/opt/build/repo/layers/ui/assets/icons/review/Use.svg?raw" /* webpackChunkName: "assets/icons/review/Use" */).then(c => c.default || c),
  "review/fallback": () => import("/opt/build/repo/layers/ui/assets/icons/review/fallback.svg?raw" /* webpackChunkName: "assets/icons/review/fallback" */).then(c => c.default || c),
  "search": () => import("/opt/build/repo/layers/ui/assets/icons/search.svg?raw" /* webpackChunkName: "assets/icons/search" */).then(c => c.default || c),
  "send": () => import("/opt/build/repo/layers/ui/assets/icons/send.svg?raw" /* webpackChunkName: "assets/icons/send" */).then(c => c.default || c),
  "settings": () => import("/opt/build/repo/layers/ui/assets/icons/settings.svg?raw" /* webpackChunkName: "assets/icons/settings" */).then(c => c.default || c),
  "share-arrow": () => import("/opt/build/repo/layers/ui/assets/icons/share-arrow.svg?raw" /* webpackChunkName: "assets/icons/share-arrow" */).then(c => c.default || c),
  "speed-dashboard": () => import("/opt/build/repo/layers/ui/assets/icons/speed-dashboard.svg?raw" /* webpackChunkName: "assets/icons/speed-dashboard" */).then(c => c.default || c),
  "sublease": () => import("/opt/build/repo/layers/ui/assets/icons/sublease.svg?raw" /* webpackChunkName: "assets/icons/sublease" */).then(c => c.default || c),
  "suitcase-portfolio": () => import("/opt/build/repo/layers/ui/assets/icons/suitcase-portfolio.svg?raw" /* webpackChunkName: "assets/icons/suitcase-portfolio" */).then(c => c.default || c),
  "table_edit_icon": () => import("/opt/build/repo/layers/ui/assets/icons/table_edit_icon.svg?raw" /* webpackChunkName: "assets/icons/table_edit_icon" */).then(c => c.default || c),
  "tasklist-calculator": () => import("/opt/build/repo/layers/ui/assets/icons/tasklist-calculator.svg?raw" /* webpackChunkName: "assets/icons/tasklist-calculator" */).then(c => c.default || c),
  "text-square": () => import("/opt/build/repo/layers/ui/assets/icons/text-square.svg?raw" /* webpackChunkName: "assets/icons/text-square" */).then(c => c.default || c),
  "top-left-faded-border": () => import("/opt/build/repo/layers/ui/assets/icons/top-left-faded-border.svg?raw" /* webpackChunkName: "assets/icons/top-left-faded-border" */).then(c => c.default || c),
  "trash": () => import("/opt/build/repo/layers/ui/assets/icons/trash.svg?raw" /* webpackChunkName: "assets/icons/trash" */).then(c => c.default || c),
  "undo": () => import("/opt/build/repo/layers/ui/assets/icons/undo.svg?raw" /* webpackChunkName: "assets/icons/undo" */).then(c => c.default || c),
  "upload-01": () => import("/opt/build/repo/layers/ui/assets/icons/upload-01.svg?raw" /* webpackChunkName: "assets/icons/upload-01" */).then(c => c.default || c),
  "upload-cloud": () => import("/opt/build/repo/layers/ui/assets/icons/upload-cloud.svg?raw" /* webpackChunkName: "assets/icons/upload-cloud" */).then(c => c.default || c),
  "upload": () => import("/opt/build/repo/layers/ui/assets/icons/upload.svg?raw" /* webpackChunkName: "assets/icons/upload" */).then(c => c.default || c),
  "user-add": () => import("/opt/build/repo/layers/ui/assets/icons/user-add.svg?raw" /* webpackChunkName: "assets/icons/user-add" */).then(c => c.default || c),
  "user-profile": () => import("/opt/build/repo/layers/ui/assets/icons/user-profile.svg?raw" /* webpackChunkName: "assets/icons/user-profile" */).then(c => c.default || c),
  "users": () => import("/opt/build/repo/layers/ui/assets/icons/users.svg?raw" /* webpackChunkName: "assets/icons/users" */).then(c => c.default || c),
  "warning": () => import("/opt/build/repo/layers/ui/assets/icons/warning.svg?raw" /* webpackChunkName: "assets/icons/warning" */).then(c => c.default || c)
}

export default iconImports
