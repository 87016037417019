import {
  GoogleAddressComponent,
  GoogleAddressType,
  GoogleAddress,
  Address,
} from '~/types/googleAddress'

/**
 * PlaceService
 * https://developers.google.com/maps/documentation/javascript/reference/places-service
 *
 * AutoCompleteService
 * https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service
 *
 * Generate token for autocomplete request
 * https://developers.google.com/maps/documentation/javascript/place-autocomplete#session_tokens
 */

export const parseGoogleAddress = (
  addrComponents: GoogleAddressComponent[] | undefined,
) => {
  if (!addrComponents?.length) return undefined

  const result = {
    long: {},
    short: {},
  } as {
    long: Record<GoogleAddressType, string>
    short: Record<GoogleAddressType, string>
  }

  for (const addrComponent of addrComponents) {
    const type = addrComponent.types[0]
    result.long[type] = addrComponent.long_name
    result.short[type] = addrComponent.short_name
  }

  return result
}

/**
 * Given a Google address object, map it to our address object
 * @param addr Google address object
 * @returns address object which can be used to create or update address
 */
export const mapGoogleAddress = (addr: GoogleAddress): Address => ({
  address: addr.formatted_address,
  city: addr.parsed?.long.locality || '',
  state: addr.parsed?.long.administrative_area_level_1 || '',
  countryId: addr.parsed?.short.country || '',
  postcode: addr.parsed?.long.postal_code || '',
  longitude: addr.geometry?.location?.lng() || 0,
  latitude: addr.geometry?.location?.lat() || 0,
})

/**
 * Google auto complete functions
 *
 * Step1. search address by query string with getPlacePredictions.
 * it will return a list of address with place_id, which can be used to get the detail of address in next step.
 *
 * Step2. get the detail of selected address with getPlaceDetails
 *
 * For further configuration:
 * https://developers.google.com/maps/documentation/javascript/place-autocomplete
 */
export const useGoogleAddressAutoComplete = (options: any = {}) => {
  const PlacesLibrary = useGoogleMapsImportLibrary('places')
  const placesService = ref<google.maps.places.PlacesService>(null!)
  const autoCompleteService = ref<google.maps.places.AutocompleteService>(null!)
  const sessionTokenGenerator = ref<
    typeof google.maps.places.AutocompleteSessionToken
  >(null!)
  const sessionToken = ref<google.maps.places.AutocompleteSessionToken>(null!)

  watch(
    PlacesLibrary,
    (PlacesLibrary) => {
      if (!PlacesLibrary) return

      // init services
      placesService.value = new PlacesLibrary.PlacesService(
        document.createElement('div'),
      )
      autoCompleteService.value = new PlacesLibrary.AutocompleteService()
      sessionTokenGenerator.value = PlacesLibrary.AutocompleteSessionToken
      sessionToken.value = new PlacesLibrary.AutocompleteSessionToken()
    },
    { immediate: true },
  )

  const getPlacePredictions = async (query: string, options: any = {}) => {
    const { currentDivision } = useDivisions()

    const result = await autoCompleteService.value?.getPlacePredictions({
      input: query,
      sessionToken: sessionToken.value,
      componentRestrictions: {
        country: currentDivision.value.countryId.toLowerCase(),
      },
      // types: [
      //   'address',
      //   'shopping_mall',
      //   'airport',
      //   'bank',
      //   'art_gallery',
      //   'bakery',
      //   'bar',
      //   'beauty_salon',
      //   'bicycle_store',
      //   'book_store',
      //   'cafe',
      //   'car_dealer',
      //   'car_rental',
      //   'car_wash',
      //   'casino',
      //   'clothing_store',
      //   'convenience_store',
      //   'doctor',
      //   'dentist',
      //   'department_store',
      //   'drugstore',
      //   'florist',
      //   'furniture_store',
      //   'gym',
      //   'hair_care',
      //   'hardware_store',
      //   'home_goods_store',
      //   'hospital',
      //   'jewelry_store',
      //   'library',
      //   'liquor_store',
      //   'museum',
      //   'parking',
      //   'pet_store',
      //   'pharmacy',
      //   'post_office',
      //   'primary_school',
      //   'real_estate_agency',
      //   'restaurant',
      //   'school',
      //   'secondary_school',
      //   'shoe_store',
      //   'spa',
      //   'storage',
      //   'supermarket',
      //   'travel_agency',
      //   'university',
      //   'veterinary_care',
      // ],
      ...options,
    })

    return result?.predictions || []
  }

  const getPlaceDetails = async (
    placeId: string,
    callback: (addr: any) => void,
    options: any = {},
  ) => {
    await placesService.value?.getDetails(
      {
        placeId,
        fields: ['address_components', 'formatted_address', 'geometry', 'name'],
        sessionToken: sessionToken.value,
        ...options,
      },
      (addr, status) => {
        if (status !== 'OK') return
        // parse address components
        const parsed = parseGoogleAddress(
          addr?.address_components as GoogleAddressComponent[],
        )
        const result: GoogleAddress = {
          ...addr,
          parsed,
        }
        callback(result)
        // clear session token after place detail request
        sessionToken.value = new sessionTokenGenerator.value()
      },
    )
  }

  return {
    getPlacePredictions,
    getPlaceDetails,
    mapGoogleAddress,
  }
}
