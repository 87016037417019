import { FieldSelectionState } from '@shared/composables/useAnnotations'
import { type ILeaseField, useLeaseStore } from '@register'

export const useFieldSelectionStore = defineStore('fieldSelection', () => {
  const { lease } = storeToRefs(useLeaseStore())

  const sectionContainerDiv = ref<HTMLElement>()
  // The field that is currently selected
  const selectedField = ref<ILeaseField>()
  // The state of the field selection, which contains bounds, page and snippet
  const fieldSelectionState = reactive<FieldSelectionState>({
    id: undefined,
    documentId: undefined,
    bounds: undefined,
    snippet: undefined,
    page: undefined,
  })
  // The row position of the selected field to position the snippet
  const snippetRowPosition = useSnipPosition(
    computed(() => selectedField.value?.id),
    (id: string | undefined) => {
      const section = lease.value?.groups
        ?.flatMap((group) => group.sections)
        .find((section) => section?.fields?.some((field) => field.id === id))
      return section?.fields?.findIndex((field) => field.id === id) ?? -1
    },
    sectionContainerDiv,
  )

  const reset = () => {
    selectedField.value = undefined
    fieldSelectionState.id = undefined
    fieldSelectionState.documentId = undefined
    fieldSelectionState.bounds = undefined
    fieldSelectionState.snippet = undefined
    fieldSelectionState.page = undefined
  }

  return {
    selectedField,
    fieldSelectionState,
    snippetRowPosition,
    sectionContainerDiv,
    reset,
  }
})
