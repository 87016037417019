import { default as _404B00KTGNlR5Meta } from "/opt/build/repo/pages/404.vue?macro=true";
import { default as create_45profileWvrSOkAjTwMeta } from "/opt/build/repo/layers/authentication/pages/account/create-profile.vue?macro=true";
import { default as invitationVBFaoBLMrfMeta } from "/opt/build/repo/layers/authentication/pages/account/invitation.vue?macro=true";
import { default as signup_45completed9Dfx2qRcWwMeta } from "/opt/build/repo/layers/authentication/pages/account/signup-completed.vue?macro=true";
import { default as force_45logoutEpcTmmNcJHMeta } from "/opt/build/repo/layers/authentication/pages/auth/force-logout.vue?macro=true";
import { default as Register6DbQxaxR5mMeta } from "/opt/build/repo/layers/authentication/pages/auth/Register.vue?macro=true";
import { default as callbackaUD77BP2RdMeta } from "/opt/build/repo/layers/authentication/pages/callback.vue?macro=true";
import { default as schedule_45exceptionsnJGpXE6u65Meta } from "/opt/build/repo/layers/audit/pages/division/[companyId]/audit/comparison/schedule-exceptions.vue?macro=true";
import { default as schedule_45variancebsqIG39oPuMeta } from "/opt/build/repo/layers/audit/pages/division/[companyId]/audit/comparison/schedule-variance.vue?macro=true";
import { default as comparisonF7eLUz5oL4Meta } from "/opt/build/repo/layers/audit/pages/division/[companyId]/audit/comparison.vue?macro=true";
import { default as indexFYOzIGDf84Meta } from "/opt/build/repo/layers/audit/pages/division/[companyId]/audit/index.vue?macro=true";
import { default as audit7T42qarxcEMeta } from "/opt/build/repo/layers/audit/pages/division/[companyId]/audit.vue?macro=true";
import { default as indexF2SYOEEjbZMeta } from "/opt/build/repo/layers/critical-dates/pages/division/[companyId]/critical-dates/index.vue?macro=true";
import { default as _91triggerId_93S9g5HCzEVeMeta } from "/opt/build/repo/layers/critical-dates/pages/division/[companyId]/critical-dates/triggers/[triggerId].vue?macro=true";
import { default as indexfGXJjotFhtMeta } from "/opt/build/repo/layers/critical-dates/pages/division/[companyId]/critical-dates/triggers/index.vue?macro=true";
import { default as critical_45datesY0mNSfwOFiMeta } from "/opt/build/repo/layers/critical-dates/pages/division/[companyId]/critical-dates.vue?macro=true";
import { default as indextNgz7dmy7KMeta } from "/opt/build/repo/layers/division/pages/division/[companyId]/index.vue?macro=true";
import { default as _91amendmentId_93R4XvhtCgInMeta } from "/opt/build/repo/layers/register/pages/division/[companyId]/lease/[leaseDocumentId]/amendments/[amendmentId].vue?macro=true";
import { default as indexzuqu2xMBg1Meta } from "/opt/build/repo/layers/register/pages/division/[companyId]/lease/[leaseDocumentId]/amendments/index.vue?macro=true";
import { default as amendmentsrnSP2e36MVMeta } from "/opt/build/repo/layers/register/pages/division/[companyId]/lease/[leaseDocumentId]/amendments.vue?macro=true";
import { default as documentsDqJZ4zaocRMeta } from "/opt/build/repo/layers/register/pages/division/[companyId]/lease/[leaseDocumentId]/documents.vue?macro=true";
import { default as headleasegwdw2UNbQrMeta } from "/opt/build/repo/layers/register/pages/division/[companyId]/lease/[leaseDocumentId]/headlease.vue?macro=true";
import { default as indexrbCXjQ66tvMeta } from "/opt/build/repo/layers/register/pages/division/[companyId]/lease/[leaseDocumentId]/index.vue?macro=true";
import { default as lease4e8gErKvQdMeta } from "/opt/build/repo/layers/register/pages/division/[companyId]/lease/[leaseDocumentId]/lease.vue?macro=true";
import { default as _91id_93tdsr75c85iMeta } from "/opt/build/repo/layers/manager/pages/division/[companyId]/lease/[leaseDocumentId]/manager/[referenceName]/[id].vue?macro=true";
import { default as indexXr3qzYLPWJMeta } from "/opt/build/repo/layers/manager/pages/division/[companyId]/lease/[leaseDocumentId]/manager/index.vue?macro=true";
import { default as managerv2Uk1GUiA8Meta } from "/opt/build/repo/layers/manager/pages/division/[companyId]/lease/[leaseDocumentId]/manager.vue?macro=true";
import { default as remindersNQYJQYoHBnMeta } from "/opt/build/repo/layers/register/pages/division/[companyId]/lease/[leaseDocumentId]/reminders.vue?macro=true";
import { default as settingszNx1CSUX3tMeta } from "/opt/build/repo/layers/register/pages/division/[companyId]/lease/[leaseDocumentId]/settings.vue?macro=true";
import { default as subleasesEQvgJydHNcMeta } from "/opt/build/repo/layers/register/pages/division/[companyId]/lease/[leaseDocumentId]/subleases.vue?macro=true";
import { default as _91leaseDocumentId_93nJK4GZtorBMeta } from "/opt/build/repo/layers/register/pages/division/[companyId]/lease/[leaseDocumentId].vue?macro=true";
import { default as _91linkDocumentId_93onuwYPKccYMeta } from "/opt/build/repo/layers/register/pages/division/[companyId]/link-document/[linkDocumentId].vue?macro=true";
import { default as indexXHB5gWNWuTMeta } from "/opt/build/repo/layers/portfolio/pages/division/[companyId]/portfolio/index.vue?macro=true";
import { default as useReportsQueryQGn3TJYKCcMeta } from "/opt/build/repo/layers/reports/pages/division/[companyId]/reports/composables/useReportsQuery.ts?macro=true";
import { default as indexywA7zkZzUGMeta } from "/opt/build/repo/layers/reports/pages/division/[companyId]/reports/index.vue?macro=true";
import { default as annualgymwP43wWMMeta } from "/opt/build/repo/layers/reports/pages/division/[companyId]/reports/view/forecast/annual.vue?macro=true";
import { default as indexbEKR61F5gRMeta } from "/opt/build/repo/layers/reports/pages/division/[companyId]/reports/view/forecast/index.vue?macro=true";
import { default as monthlyry2kOwjwMZMeta } from "/opt/build/repo/layers/reports/pages/division/[companyId]/reports/view/forecast/monthly.vue?macro=true";
import { default as forecastIQijJeTJXXMeta } from "/opt/build/repo/layers/reports/pages/division/[companyId]/reports/view/forecast.vue?macro=true";
import { default as indexxCcUzZoGW3Meta } from "/opt/build/repo/layers/reports/pages/division/[companyId]/reports/view/index.vue?macro=true";
import { default as indexIGleHNgWcsMeta } from "/opt/build/repo/layers/division/pages/division/[companyId]/settings/index.vue?macro=true";
import { default as _91section_93Doj9dVLNcsMeta } from "/opt/build/repo/layers/division/pages/division/[companyId]/settings/modules/[section].vue?macro=true";
import { default as _91groupId_93RKFJ438jbjMeta } from "/opt/build/repo/layers/division/pages/division/[companyId]/settings/modules/confidence-score/[groupId].vue?macro=true";
import { default as indexl3qofAqG8NMeta } from "/opt/build/repo/layers/division/pages/division/[companyId]/settings/modules/confidence-score/index.vue?macro=true";
import { default as confidence_45scoreishrZLvIM2Meta } from "/opt/build/repo/layers/division/pages/division/[companyId]/settings/modules/confidence-score.vue?macro=true";
import { default as indexRB1evl5KHvMeta } from "/opt/build/repo/layers/division/pages/division/[companyId]/settings/modules/upload-reference/index.vue?macro=true";
import { default as usersiaTZGnEm8pMeta } from "/opt/build/repo/layers/division/pages/division/[companyId]/settings/users.vue?macro=true";
import { default as settingshBi8W5BZnVMeta } from "/opt/build/repo/layers/division/pages/division/[companyId]/settings.vue?macro=true";
import { default as failedHAqODlSYYvMeta } from "/opt/build/repo/layers/workflow/pages/division/[companyId]/workflow/failed.vue?macro=true";
import { default as indexVaZFMBAlYcMeta } from "/opt/build/repo/layers/workflow/pages/division/[companyId]/workflow/index.vue?macro=true";
import { default as processingtXTVMysEtmMeta } from "/opt/build/repo/layers/workflow/pages/division/[companyId]/workflow/processing.vue?macro=true";
import { default as workflowEHqIeI5TfTMeta } from "/opt/build/repo/layers/workflow/pages/division/[companyId]/workflow.vue?macro=true";
import { default as indexabOJ19U5J5Meta } from "/opt/build/repo/layers/dashboard/pages/index.vue?macro=true";
import { default as _91groupId_93vhJLXshqRTMeta } from "/opt/build/repo/layers/settings/pages/settings/confidence-score/[groupId].vue?macro=true";
import { default as indexctgbcMT4FDMeta } from "/opt/build/repo/layers/settings/pages/settings/confidence-score/index.vue?macro=true";
import { default as confidence_45scoreFdkauB6XjlMeta } from "/opt/build/repo/layers/settings/pages/settings/confidence-score.vue?macro=true";
import { default as createlGCGcpviS0Meta } from "/opt/build/repo/layers/settings/pages/settings/divisions/create.vue?macro=true";
import { default as index0ahCeIvzezMeta } from "/opt/build/repo/layers/settings/pages/settings/divisions/index.vue?macro=true";
import { default as indexT1PSPxfkbnMeta } from "/opt/build/repo/layers/settings/pages/settings/profile/index.vue?macro=true";
import { default as index9pVNJwoO9tMeta } from "/opt/build/repo/layers/settings/pages/settings/upload-reference/index.vue?macro=true";
import { default as indexmQbwge5nNcMeta } from "/opt/build/repo/layers/settings/pages/settings/users/[userId]/index.vue?macro=true";
import { default as permissions0zU1Dj9lCPMeta } from "/opt/build/repo/layers/settings/pages/settings/users/[userId]/permissions.vue?macro=true";
import { default as indexbWF4FOcH2RMeta } from "/opt/build/repo/layers/settings/pages/settings/users/index.vue?macro=true";
import { default as invite_45userqf0BovFMjmMeta } from "/opt/build/repo/layers/settings/pages/settings/users/invite-user.vue?macro=true";
import { default as settingsPm2MH03QgaMeta } from "/opt/build/repo/layers/settings/pages/settings.vue?macro=true";
import { default as unsupported_45browserHJmWimS945Meta } from "/opt/build/repo/pages/unsupported-browser.vue?macro=true";
export default [
  {
    name: "404",
    path: "/404",
    component: () => import("/opt/build/repo/pages/404.vue").then(m => m.default || m)
  },
  {
    name: "account-create-profile",
    path: "/account/create-profile",
    component: () => import("/opt/build/repo/layers/authentication/pages/account/create-profile.vue").then(m => m.default || m)
  },
  {
    name: "account-invitation",
    path: "/account/invitation",
    meta: invitationVBFaoBLMrfMeta || {},
    component: () => import("/opt/build/repo/layers/authentication/pages/account/invitation.vue").then(m => m.default || m)
  },
  {
    name: "account-signup-completed",
    path: "/account/signup-completed",
    meta: signup_45completed9Dfx2qRcWwMeta || {},
    component: () => import("/opt/build/repo/layers/authentication/pages/account/signup-completed.vue").then(m => m.default || m)
  },
  {
    name: "auth-force-logout",
    path: "/auth/force-logout",
    component: () => import("/opt/build/repo/layers/authentication/pages/auth/force-logout.vue").then(m => m.default || m)
  },
  {
    name: "auth-Register",
    path: "/auth/Register",
    meta: Register6DbQxaxR5mMeta || {},
    component: () => import("/opt/build/repo/layers/authentication/pages/auth/Register.vue").then(m => m.default || m)
  },
  {
    name: "callback",
    path: "/callback",
    meta: callbackaUD77BP2RdMeta || {},
    component: () => import("/opt/build/repo/layers/authentication/pages/callback.vue").then(m => m.default || m)
  },
  {
    name: audit7T42qarxcEMeta?.name,
    path: "/division/:companyId()/audit",
    meta: audit7T42qarxcEMeta || {},
    component: () => import("/opt/build/repo/layers/audit/pages/division/[companyId]/audit.vue").then(m => m.default || m),
    children: [
  {
    name: "division-companyId-audit-comparison",
    path: "comparison",
    meta: comparisonF7eLUz5oL4Meta || {},
    component: () => import("/opt/build/repo/layers/audit/pages/division/[companyId]/audit/comparison.vue").then(m => m.default || m),
    children: [
  {
    name: "division-companyId-audit-comparison-schedule-exceptions",
    path: "schedule-exceptions",
    component: () => import("/opt/build/repo/layers/audit/pages/division/[companyId]/audit/comparison/schedule-exceptions.vue").then(m => m.default || m)
  },
  {
    name: "division-companyId-audit-comparison-schedule-variance",
    path: "schedule-variance",
    component: () => import("/opt/build/repo/layers/audit/pages/division/[companyId]/audit/comparison/schedule-variance.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "division-companyId-audit",
    path: "",
    meta: indexFYOzIGDf84Meta || {},
    component: () => import("/opt/build/repo/layers/audit/pages/division/[companyId]/audit/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: critical_45datesY0mNSfwOFiMeta?.name,
    path: "/division/:companyId()/critical-dates",
    meta: critical_45datesY0mNSfwOFiMeta || {},
    component: () => import("/opt/build/repo/layers/critical-dates/pages/division/[companyId]/critical-dates.vue").then(m => m.default || m),
    children: [
  {
    name: "division-companyId-critical-dates",
    path: "",
    meta: indexF2SYOEEjbZMeta || {},
    component: () => import("/opt/build/repo/layers/critical-dates/pages/division/[companyId]/critical-dates/index.vue").then(m => m.default || m)
  },
  {
    name: "division-companyId-critical-dates-triggers-triggerId",
    path: "triggers/:triggerId()",
    meta: _91triggerId_93S9g5HCzEVeMeta || {},
    component: () => import("/opt/build/repo/layers/critical-dates/pages/division/[companyId]/critical-dates/triggers/[triggerId].vue").then(m => m.default || m)
  },
  {
    name: "division-companyId-critical-dates-triggers",
    path: "triggers",
    meta: indexfGXJjotFhtMeta || {},
    component: () => import("/opt/build/repo/layers/critical-dates/pages/division/[companyId]/critical-dates/triggers/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "division-companyId",
    path: "/division/:companyId()",
    meta: indextNgz7dmy7KMeta || {},
    component: () => import("/opt/build/repo/layers/division/pages/division/[companyId]/index.vue").then(m => m.default || m)
  },
  {
    name: _91leaseDocumentId_93nJK4GZtorBMeta?.name,
    path: "/division/:companyId()/lease/:leaseDocumentId()",
    meta: _91leaseDocumentId_93nJK4GZtorBMeta || {},
    component: () => import("/opt/build/repo/layers/register/pages/division/[companyId]/lease/[leaseDocumentId].vue").then(m => m.default || m),
    children: [
  {
    name: amendmentsrnSP2e36MVMeta?.name,
    path: "amendments",
    component: () => import("/opt/build/repo/layers/register/pages/division/[companyId]/lease/[leaseDocumentId]/amendments.vue").then(m => m.default || m),
    children: [
  {
    name: "division-companyId-lease-leaseDocumentId-amendments-amendmentId",
    path: ":amendmentId()",
    component: () => import("/opt/build/repo/layers/register/pages/division/[companyId]/lease/[leaseDocumentId]/amendments/[amendmentId].vue").then(m => m.default || m)
  },
  {
    name: "division-companyId-lease-leaseDocumentId-amendments",
    path: "",
    meta: indexzuqu2xMBg1Meta || {},
    component: () => import("/opt/build/repo/layers/register/pages/division/[companyId]/lease/[leaseDocumentId]/amendments/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "division-companyId-lease-leaseDocumentId-documents",
    path: "documents",
    component: () => import("/opt/build/repo/layers/register/pages/division/[companyId]/lease/[leaseDocumentId]/documents.vue").then(m => m.default || m)
  },
  {
    name: "division-companyId-lease-leaseDocumentId-headlease",
    path: "headlease",
    component: () => import("/opt/build/repo/layers/register/pages/division/[companyId]/lease/[leaseDocumentId]/headlease.vue").then(m => m.default || m)
  },
  {
    name: "division-companyId-lease-leaseDocumentId",
    path: "",
    component: () => import("/opt/build/repo/layers/register/pages/division/[companyId]/lease/[leaseDocumentId]/index.vue").then(m => m.default || m)
  },
  {
    name: "division-companyId-lease-leaseDocumentId-lease",
    path: "lease",
    component: () => import("/opt/build/repo/layers/register/pages/division/[companyId]/lease/[leaseDocumentId]/lease.vue").then(m => m.default || m)
  },
  {
    name: managerv2Uk1GUiA8Meta?.name,
    path: "manager",
    meta: managerv2Uk1GUiA8Meta || {},
    component: () => import("/opt/build/repo/layers/manager/pages/division/[companyId]/lease/[leaseDocumentId]/manager.vue").then(m => m.default || m),
    children: [
  {
    name: "division-companyId-lease-leaseDocumentId-manager-referenceName-id",
    path: ":referenceName()/:id()",
    component: () => import("/opt/build/repo/layers/manager/pages/division/[companyId]/lease/[leaseDocumentId]/manager/[referenceName]/[id].vue").then(m => m.default || m)
  },
  {
    name: "division-companyId-lease-leaseDocumentId-manager",
    path: "",
    meta: indexXr3qzYLPWJMeta || {},
    component: () => import("/opt/build/repo/layers/manager/pages/division/[companyId]/lease/[leaseDocumentId]/manager/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "division-companyId-lease-leaseDocumentId-reminders",
    path: "reminders",
    meta: remindersNQYJQYoHBnMeta || {},
    component: () => import("/opt/build/repo/layers/register/pages/division/[companyId]/lease/[leaseDocumentId]/reminders.vue").then(m => m.default || m)
  },
  {
    name: "division-companyId-lease-leaseDocumentId-settings",
    path: "settings",
    component: () => import("/opt/build/repo/layers/register/pages/division/[companyId]/lease/[leaseDocumentId]/settings.vue").then(m => m.default || m)
  },
  {
    name: "division-companyId-lease-leaseDocumentId-subleases",
    path: "subleases",
    meta: subleasesEQvgJydHNcMeta || {},
    component: () => import("/opt/build/repo/layers/register/pages/division/[companyId]/lease/[leaseDocumentId]/subleases.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "division-companyId-link-document-linkDocumentId",
    path: "/division/:companyId()/link-document/:linkDocumentId()",
    component: () => import("/opt/build/repo/layers/register/pages/division/[companyId]/link-document/[linkDocumentId].vue").then(m => m.default || m)
  },
  {
    name: "division-companyId-portfolio",
    path: "/division/:companyId()/portfolio",
    meta: indexXHB5gWNWuTMeta || {},
    component: () => import("/opt/build/repo/layers/portfolio/pages/division/[companyId]/portfolio/index.vue").then(m => m.default || m)
  },
  {
    name: "division-companyId-reports-composables-useReportsQuery",
    path: "/division/:companyId()/reports/composables/useReportsQuery",
    component: () => import("/opt/build/repo/layers/reports/pages/division/[companyId]/reports/composables/useReportsQuery.ts").then(m => m.default || m)
  },
  {
    name: "division-companyId-reports",
    path: "/division/:companyId()/reports",
    component: () => import("/opt/build/repo/layers/reports/pages/division/[companyId]/reports/index.vue").then(m => m.default || m)
  },
  {
    name: forecastIQijJeTJXXMeta?.name,
    path: "/division/:companyId()/reports/view/forecast",
    component: () => import("/opt/build/repo/layers/reports/pages/division/[companyId]/reports/view/forecast.vue").then(m => m.default || m),
    children: [
  {
    name: "division-companyId-reports-view-forecast-annual",
    path: "annual",
    component: () => import("/opt/build/repo/layers/reports/pages/division/[companyId]/reports/view/forecast/annual.vue").then(m => m.default || m)
  },
  {
    name: "division-companyId-reports-view-forecast",
    path: "",
    component: () => import("/opt/build/repo/layers/reports/pages/division/[companyId]/reports/view/forecast/index.vue").then(m => m.default || m)
  },
  {
    name: "division-companyId-reports-view-forecast-monthly",
    path: "monthly",
    component: () => import("/opt/build/repo/layers/reports/pages/division/[companyId]/reports/view/forecast/monthly.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "division-companyId-reports-view",
    path: "/division/:companyId()/reports/view",
    meta: indexxCcUzZoGW3Meta || {},
    component: () => import("/opt/build/repo/layers/reports/pages/division/[companyId]/reports/view/index.vue").then(m => m.default || m)
  },
  {
    name: settingshBi8W5BZnVMeta?.name,
    path: "/division/:companyId()/settings",
    meta: settingshBi8W5BZnVMeta || {},
    component: () => import("/opt/build/repo/layers/division/pages/division/[companyId]/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "division-companyId-settings",
    path: "",
    meta: indexIGleHNgWcsMeta || {},
    component: () => import("/opt/build/repo/layers/division/pages/division/[companyId]/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "division-companyId-settings-modules-section",
    path: "modules/:section()",
    meta: _91section_93Doj9dVLNcsMeta || {},
    component: () => import("/opt/build/repo/layers/division/pages/division/[companyId]/settings/modules/[section].vue").then(m => m.default || m)
  },
  {
    name: confidence_45scoreishrZLvIM2Meta?.name,
    path: "modules/confidence-score",
    component: () => import("/opt/build/repo/layers/division/pages/division/[companyId]/settings/modules/confidence-score.vue").then(m => m.default || m),
    children: [
  {
    name: "division-companyId-settings-modules-confidence-score-groupId",
    path: ":groupId()",
    component: () => import("/opt/build/repo/layers/division/pages/division/[companyId]/settings/modules/confidence-score/[groupId].vue").then(m => m.default || m)
  },
  {
    name: "division-companyId-settings-modules-confidence-score",
    path: "",
    component: () => import("/opt/build/repo/layers/division/pages/division/[companyId]/settings/modules/confidence-score/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "division-companyId-settings-modules-upload-reference",
    path: "modules/upload-reference",
    component: () => import("/opt/build/repo/layers/division/pages/division/[companyId]/settings/modules/upload-reference/index.vue").then(m => m.default || m)
  },
  {
    name: "division-companyId-settings-users",
    path: "users",
    meta: usersiaTZGnEm8pMeta || {},
    component: () => import("/opt/build/repo/layers/division/pages/division/[companyId]/settings/users.vue").then(m => m.default || m)
  }
]
  },
  {
    name: workflowEHqIeI5TfTMeta?.name,
    path: "/division/:companyId()/workflow",
    meta: workflowEHqIeI5TfTMeta || {},
    component: () => import("/opt/build/repo/layers/workflow/pages/division/[companyId]/workflow.vue").then(m => m.default || m),
    children: [
  {
    name: "division-companyId-workflow-failed",
    path: "failed",
    component: () => import("/opt/build/repo/layers/workflow/pages/division/[companyId]/workflow/failed.vue").then(m => m.default || m)
  },
  {
    name: "division-companyId-workflow",
    path: "",
    component: () => import("/opt/build/repo/layers/workflow/pages/division/[companyId]/workflow/index.vue").then(m => m.default || m)
  },
  {
    name: "division-companyId-workflow-processing",
    path: "processing",
    component: () => import("/opt/build/repo/layers/workflow/pages/division/[companyId]/workflow/processing.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index",
    path: "/",
    meta: indexabOJ19U5J5Meta || {},
    component: () => import("/opt/build/repo/layers/dashboard/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/opt/build/repo/layers/settings/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: confidence_45scoreFdkauB6XjlMeta?.name,
    path: "confidence-score",
    component: () => import("/opt/build/repo/layers/settings/pages/settings/confidence-score.vue").then(m => m.default || m),
    children: [
  {
    name: "settings-confidence-score-groupId",
    path: ":groupId()",
    component: () => import("/opt/build/repo/layers/settings/pages/settings/confidence-score/[groupId].vue").then(m => m.default || m)
  },
  {
    name: "settings-confidence-score",
    path: "",
    component: () => import("/opt/build/repo/layers/settings/pages/settings/confidence-score/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "settings-divisions-create",
    path: "divisions/create",
    meta: createlGCGcpviS0Meta || {},
    component: () => import("/opt/build/repo/layers/settings/pages/settings/divisions/create.vue").then(m => m.default || m)
  },
  {
    name: "settings-divisions",
    path: "divisions",
    component: () => import("/opt/build/repo/layers/settings/pages/settings/divisions/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-profile",
    path: "profile",
    component: () => import("/opt/build/repo/layers/settings/pages/settings/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-upload-reference",
    path: "upload-reference",
    meta: index9pVNJwoO9tMeta || {},
    component: () => import("/opt/build/repo/layers/settings/pages/settings/upload-reference/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-users-userId",
    path: "users/:userId()",
    meta: indexmQbwge5nNcMeta || {},
    component: () => import("/opt/build/repo/layers/settings/pages/settings/users/[userId]/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-users-userId-permissions",
    path: "users/:userId()/permissions",
    meta: permissions0zU1Dj9lCPMeta || {},
    component: () => import("/opt/build/repo/layers/settings/pages/settings/users/[userId]/permissions.vue").then(m => m.default || m)
  },
  {
    name: "settings-users",
    path: "users",
    component: () => import("/opt/build/repo/layers/settings/pages/settings/users/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-users-invite-user",
    path: "users/invite-user",
    meta: invite_45userqf0BovFMjmMeta || {},
    component: () => import("/opt/build/repo/layers/settings/pages/settings/users/invite-user.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "unsupported-browser",
    path: "/unsupported-browser",
    meta: unsupported_45browserHJmWimS945Meta || {},
    component: () => import("/opt/build/repo/pages/unsupported-browser.vue").then(m => m.default || m)
  }
]