import routerOptions0 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.15_eslint@8.57.0_sass@1.77.8_typescript@5.5.4_vite@5.4.0_vue-tsc@2.0.29/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/opt/build/repo/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}