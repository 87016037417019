import type { AuthUser } from '@authentication'
import type { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent'

export const useNewRelic = () => {
  const newRelic = useNuxtApp().$newRelic
  const runtimeConfig = useRuntimeConfig()

  const actionText = (text: string) => {
    newRelic.interaction().actionText(text)
  }

  const log: BrowserAgent['log'] = (message, options) => {
    newRelic.log(message, options)

    if (runtimeConfig.public.ENV === 'local') {
      console.log(message, options)
    }
  }

  const login = (user: AuthUser) => {
    newRelic.setUserId(user?.id ?? 'anonymous')

    newRelic.setCustomAttribute('user', user?.name ?? 'anonymous')
    newRelic.setCustomAttribute('email', user?.email ?? 'anonymous@email.com')

    newRelic.setCustomAttribute(
      'organization.name',
      user?.organization.name ?? 'anonymous',
    )

    newRelic.setCustomAttribute(
      'organization.id',
      user?.organization.id ?? 'anonymous',
    )

    if (user?.permissions) {
      const permissions = user?.permissions.map((permission) => {
        return permission.roleId
      })
      newRelic.setCustomAttribute('permissions', permissions.join(', '))
    }

    newRelic.setCustomAttribute('products', user?.products.join(', ') ?? 'N/A')
  }

  const logout = () => {
    newRelic.setCustomAttribute('user', 'anonymous')
    newRelic.setCustomAttribute('email', 'anonymous@email.com')
    newRelic.setUserId(null)
  }

  const setCustomAttribute = (key: string, value: string) => {
    newRelic.setCustomAttribute(key, value)
  }

  // set this on all request errors and try catch errors
  const setError = (error: Error) => {
    newRelic.noticeError(error)
  }

  const addPageAction = (name: string, attributes?: object | undefined) => {
    newRelic.addPageAction(name, attributes)
  }

  return {
    actionText,
    log,
    login,
    logout,
    setCustomAttribute,
    setError,
    addPageAction,
  }
}
