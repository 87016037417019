import revive_payload_client_lXLjnsBV2V from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.15_eslint@8.57.0_sass@1.77.8_typescript@5.5.4_vite@5.4.0_vue-tsc@2.0.29/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_G7pzSJMKeY from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.15_eslint@8.57.0_sass@1.77.8_typescript@5.5.4_vite@5.4.0_vue-tsc@2.0.29/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_1TYfBKH1dB from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.15_eslint@8.57.0_sass@1.77.8_typescript@5.5.4_vite@5.4.0_vue-tsc@2.0.29/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_jBaPGufjt1 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.15_eslint@8.57.0_sass@1.77.8_typescript@5.5.4_vite@5.4.0_vue-tsc@2.0.29/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_MfBe3ecrut from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.15_eslint@8.57.0_sass@1.77.8_typescript@5.5.4_vite@5.4.0_vue-tsc@2.0.29/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_bvuqKA02hM from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.15_eslint@8.57.0_sass@1.77.8_typescript@5.5.4_vite@5.4.0_vue-tsc@2.0.29/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_oAzbY0v5pt from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.5.3_typescript@5.5.4_vue@3.4.37/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_IeGDyWP2Gi from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.15_eslint@8.57.0_sass@1.77.8_typescript@5.5.4_vite@5.4.0_vue-tsc@2.0.29/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import formkitPlugin_pZqjah0RUG from "/opt/build/repo/.nuxt/formkitPlugin.mjs";
import floating_vue_EIcJ7xiw0h from "/opt/build/repo/.nuxt/floating-vue.mjs";
import plugin_3mDLlUpvJA from "/opt/build/repo/node_modules/.pnpm/nuxt-highcharts@3.1.1/node_modules/nuxt-highcharts/lib/plugin.js";
import plugin_client_5OGoPfE5jR from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.4.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_XjOJCViDkL from "/opt/build/repo/node_modules/.pnpm/nuxt-supported-browsers@0.0.5/node_modules/nuxt-supported-browsers/dist/runtime/plugin.mjs";
import plugin_fpNl3JuMTj from "/opt/build/repo/node_modules/.pnpm/mixpanel-nuxt@0.0.12/node_modules/mixpanel-nuxt/dist/runtime/plugin.js";
import plugin_gyX6QhdXAH from "/opt/build/repo/node_modules/.pnpm/dayjs-nuxt@2.1.9/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import plugin_fUEvfpaGmZ from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+device@3.1.1/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_kb5NVW0Q8L from "/opt/build/repo/layers/shared/modules/sentry/runtime/plugin.ts";
import formkit_autoanimate_SjykaUrHoO from "/opt/build/repo/node_modules/.pnpm/@flnt-accurait+web-components@0.1.22_@types+node@20.14.15_@vueuse+integrations@10.11.1_esbuil_wzlw3qlw2f2jsgpqzqizhfxney/node_modules/@flnt-accurait/web-components/plugins/formkit-autoanimate.ts";
import tailwind_merge_q1YPApwZSv from "/opt/build/repo/node_modules/.pnpm/@flnt-accurait+web-components@0.1.22_@types+node@20.14.15_@vueuse+integrations@10.11.1_esbuil_wzlw3qlw2f2jsgpqzqizhfxney/node_modules/@flnt-accurait/web-components/plugins/tailwind-merge.ts";
import vfm_bzu08wsxs6 from "/opt/build/repo/node_modules/.pnpm/@flnt-accurait+web-components@0.1.22_@types+node@20.14.15_@vueuse+integrations@10.11.1_esbuil_wzlw3qlw2f2jsgpqzqizhfxney/node_modules/@flnt-accurait/web-components/plugins/vfm.ts";
import _01_newrelic_browser_client_golEfeKy9C from "/opt/build/repo/plugins/01.newrelic-browser.client.ts";
import _02_api_bgyRz33mcA from "/opt/build/repo/plugins/02.api.ts";
import _02_casl_dK6AW1kOxW from "/opt/build/repo/plugins/02.casl.ts";
import _03_auth_0qOenMw9ot from "/opt/build/repo/plugins/03.auth.ts";
import _04_vue_query_pfhLWgmfpy from "/opt/build/repo/plugins/04.vue-query.ts";
import _05_companies_lZ1i0cuh0R from "/opt/build/repo/plugins/05.companies.ts";
import _06_toastify_YXraJs4osh from "/opt/build/repo/plugins/06.toastify.ts";
import _07_vfm_2uugo2TItU from "/opt/build/repo/plugins/07.vfm.ts";
import _08_google_maps_4T7wxetCaw from "/opt/build/repo/plugins/08.google-maps.ts";
export default [
  revive_payload_client_lXLjnsBV2V,
  unhead_G7pzSJMKeY,
  router_1TYfBKH1dB,
  payload_client_jBaPGufjt1,
  navigation_repaint_client_MfBe3ecrut,
  chunk_reload_client_bvuqKA02hM,
  plugin_vue3_oAzbY0v5pt,
  components_plugin_KR1HBZs4kY,
  prefetch_client_IeGDyWP2Gi,
  formkitPlugin_pZqjah0RUG,
  floating_vue_EIcJ7xiw0h,
  plugin_3mDLlUpvJA,
  plugin_client_5OGoPfE5jR,
  plugin_XjOJCViDkL,
  plugin_fpNl3JuMTj,
  plugin_gyX6QhdXAH,
  plugin_fUEvfpaGmZ,
  plugin_kb5NVW0Q8L,
  formkit_autoanimate_SjykaUrHoO,
  tailwind_merge_q1YPApwZSv,
  vfm_bzu08wsxs6,
  _01_newrelic_browser_client_golEfeKy9C,
  _02_api_bgyRz33mcA,
  _02_casl_dK6AW1kOxW,
  _03_auth_0qOenMw9ot,
  _04_vue_query_pfhLWgmfpy,
  _05_companies_lZ1i0cuh0R,
  _06_toastify_YXraJs4osh,
  _07_vfm_2uugo2TItU,
  _08_google_maps_4T7wxetCaw
]