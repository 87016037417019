import { AuthStatus } from '@authentication'

export default defineNuxtPlugin(async () => {
  const { status } = useAuth()
  const { fetchCompanies } = useDivisions()

  if (status.value === AuthStatus.Authenticated) {
    await fetchCompanies()
  }

  watch(
    () => status.value,
    async () => {
      if (status.value === AuthStatus.Authenticated) {
        await fetchCompanies()
      } else if (status.value === AuthStatus.Unauthenticated) {
        // TODO: clean pinia and all stores
      }
    },
  )
})
