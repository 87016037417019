import { getCurrentDivisionId } from '@shared/composables/useDivisions'
import {
  getCurrentLeaseId,
  useApiPrefetchLeaseCurrentValue,
  useApiPrefetchAffectedFields,
  useWebViewerStore,
} from '@register'

/* This composable should only be used once, in the lease page */
const [useProvideCurrentLease, _useCurrentLease] = createInjectionState(() => {
  const { mainDocumentId, currentDocumentId } = storeToRefs(useWebViewerStore())
  const divisionId = getCurrentDivisionId()
  const leaseId = getCurrentLeaseId()
  const query = useApiLeaseInfo(divisionId, leaseId)

  watchEffect(() => {
    if (query.data.value?.documents) {
      mainDocumentId.value = query.data.value?.documents[0].id
      currentDocumentId.value = query.data.value?.documents[0].id
    }
  })

  const documents = computed(() => query.data.value?.documents ?? [])

  // The name of the document to be displayed in the header
  const documentName = computed(
    () => query.data.value?.name ?? documents.value[0]?.name ?? undefined,
  )

  // Date format for this lease
  const dateFormat = computed(() => getLeaseDateFormat(query.data.value))

  return {
    ...query,
    documents,
    documentName,
    dateFormat,
  }
})

export { useProvideCurrentLease }

/* @returns Information needed no mount the lease page */
export function useCurrentLease() {
  const query = _useCurrentLease()
  if (!query)
    throw new Error('`useCurrentLease` can only be used in lease page.')
  return query
}
