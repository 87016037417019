import validate from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.15_eslint@8.57.0_sass@1.77.8_typescript@5.5.4_vite@5.4.0_vue-tsc@2.0.29/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/opt/build/repo/middleware/auth.global.ts";
export const globalMiddleware = [
  validate,
  auth_45global
]
export const namedMiddleware = {
  "report-view": () => import("/opt/build/repo/layers/reports/middleware/report-view.ts"),
  "has-company-access": () => import("/opt/build/repo/middleware/has-company-access.ts"),
  "has-critical-dates": () => import("/opt/build/repo/middleware/has-critical-dates.ts"),
  "has-lease-manager": () => import("/opt/build/repo/middleware/has-lease-manager.ts"),
  "manage-organization": () => import("/opt/build/repo/middleware/manage-organization.ts"),
  "manage-user": () => import("/opt/build/repo/middleware/manage-user.ts"),
  "redirect-to-last-division": () => import("/opt/build/repo/middleware/redirect-to-last-division.ts"),
  "single-division": () => import("/opt/build/repo/middleware/single-division.ts"),
  "update-company": () => import("/opt/build/repo/middleware/update-company.ts")
}