import { useQuery } from '@tanstack/vue-query'
import type { ILeaseInfo } from '@register'

/*
 * @returns Information needed to mount the lease page
 */
export const useApiLeaseInfo = (divisionId: string, leaseId: string) => {
  return useQuery({
    queryKey: ['companies', divisionId, 'leases', leaseId],
    queryFn: () =>
      fetchAPI<ILeaseInfo>(`/companies/${divisionId}/leases/${leaseId}`),
  })
}
