import { useGoogleMapsLoader } from '@shared/composables/google-maps'

export default defineNuxtPlugin(async (nuxtApp) => {
  const { initialize } = useGoogleMapsLoader()
  initialize({
    apiKey: useRuntimeConfig().public.googleApiKey,
    version: 'weekly',
    libraries: ['places', 'maps', 'marker'],
  })
})
