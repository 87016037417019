import { createSection } from '@formkit/inputs'
import { markRaw } from 'vue'
import Datepicker from '@ui/components/Form/Datepicker/Datepicker.vue'

/**
 * Input section used by selects
 *
 * @public
 */
export const input = createSection('input', () => ({
  $cmp: markRaw(Datepicker) as unknown as string,
  bind: '$attrs',
  props: {
    multiCalendars: '$multiCalendars',
    modelType: '$modelType',
    position: '$position',
    format: '$format',
    autoPosition: '$autoPosition',
    altPosition: '$altPosition',
    transitions: '$transitions',
    formatLocale: '$formatLocale',
    utc: '$utc',
    ariaLabels: '$ariaLabels',
    offset: '$offset',
    hideNavigation: '$hideNavigation',
    timezone: '$timezone',
    emitTimezone: '$emitTimezone',
    vertical: '$vertical',
    disableMonthYearSelect: '$disableMonthYearSelect',
    disableYearSelect: '$disableYearSelect',
    menuClassName: '$menuClassName',
    dayClass: '$dayClass',
    yearRange: '$yearRange',
    calendarCellClassName: '$calendarCellClassName',
    enableTimePicker: '$enableTimePicker',
    autoApply: '$autoApply',
    disabledDates: '$disabledDates',
    monthNameFormat: '$monthNameFormat',
    startDate: '$startDate',
    startTime: '$startTime',
    hideOffsetDates: '$hideOffsetDates',
    autoRange: '$autoRange',
    noToday: '$noToday',
    disabledWeekDays: '$disabledWeekDays',
    allowedDates: '$allowedDates',
    nowButtonLabel: '$nowButtonLabel',
    markers: '$markers',
    escClose: '$escClose',
    spaceConfirm: '$spaceConfirm',
    monthChangeOnArrows: '$monthChangeOnArrows',
    presetDates: '$presetDates',
    flow: '$flow',
    partialFlow: '$partialFlow',
    preventMinMaxNavigation: '$preventMinMaxNavigation',
    minRange: '$minRange',
    maxRange: '$maxRange',
    multiDatesLimit: '$multiDatesLimit',
    reverseYears: '$reverseYears',
    weekPicker: '$weekPicker',
    filters: '$filters',
    arrowNavigation: '$arrowNavigation',
    disableTimeRangeValidation: '$disableTimeRangeValidation',
    highlight: '$highlight',
    teleport: '$teleport',
    teleportCenter: '$teleportCenter',
    locale: '$locale',
    weekNumName: '$weekNumName',
    weekStart: '$weekStart',
    weekNumbers: '$weekNumbers',
    calendarClassName: '$calendarClassName',
    monthChangeOnScroll: '$monthChangeOnScroll',
    dayNames: '$dayNames',
    monthPicker: '$monthPicker',
    customProps: '$customProps',
    yearPicker: '$yearPicker',
    modelAuto: '$modelAuto',
    selectText: '$selectText',
    cancelText: '$cancelText',
    previewFormat: '$previewFormat',
    multiDates: '$multiDates',
    partialRange: '$partialRange',
    ignoreTimeValidation: '$ignoreTimeValidation',
    minDate: '$minDate',
    maxDate: '$maxDate',
    minTime: '$minTime',
    maxTime: '$maxTime',
    name: '$name',
    placeholder: '$placeholder',
    hideInputIcon: '$hideInputIcon',
    clearable: '$clearable',
    required: '$required',
    autocomplete: '$autocomplete',
    inputClassName: '$inputClassName',
    fixedStart: '$fixedStart',
    fixedEnd: '$fixedEnd',
    timePicker: '$timePicker',
    enableSeconds: '$enableSeconds',
    is24: '$is24',
    noHoursOverlay: '$noHoursOverlay',
    noMinutesOverlay: '$noMinutesOverlay',
    noSecondsOverlay: '$noSecondsOverlay',
    hoursGridIncrement: '$hoursGridIncrement',
    minutesGridIncrement: '$minutesGridIncrement',
    secondsGridIncrement: '$secondsGridIncrement',
    hoursIncrement: '$hoursIncrement',
    minutesIncrement: '$minutesIncrement',
    secondsIncrement: '$secondsIncrement',
    range: '$range',
    uid: '$uid',
    disabled: '$disabled',
    readonly: '$readonly',
    inline: '$inline',
    textInput: '$textInput',
    noDisabledRange: '$noDisabledRange',
    sixWeeks: '$sixWeeks',
    actionRow: '$actionRow',
    focusStartDate: '$focusStartDate',
    disabledTimes: '$disabledTimes',
    showLastInRange: '$showLastInRange',
    timePickerInline: '$timePickerInline',
    calendar: '$calendar',
    config: '$config',
    quarterPicker: '$quarterPicker',
    yearFirst: '$yearFirst',
    loading: '$loading || $state.loading',
    onInternalModelChange: '$onInternalModelChange',
    enableMinutes: '$enableMinutes',
    'onUpdate:modelValue': '$node.input',
    modelValue: '$_value',
    inputClass: '$classes.input',
    slots: '$slots',
  },
}))
